<script setup>
import { computed, ref } from 'vue';

import useLanguages from '@/composables_NEW/useLanguages';
import useAgent from '@/composables_NEW/useAgent';

import GlueButton from '@/components_NEW/formFields/GlueButton.vue';
import Tooltip from '@/components_NEW/formFields/Tooltip.vue';

import { ButtonSizes, FormBorderStyles, FormColors, TooltipColors } from '@/constants/forms';

const emit = defineEmits(['update:modelValue', 'aiResponse', 'initAIRequest']);

const { t } = useLanguages();
const { runSimpleAction } = useAgent();

const props = defineProps({
  modelValue: String,
  customMagicActions: Array,
  fieldId: String,
  showing: Boolean,
  limitChars: {
    type: Number,
    default: 10000,
  },
});

const tooltipRef = ref();

const magicActions = computed(() => {
  const ownActions = [
    {
      action: 'check_spelling',
      fetchingAction: `check_spelling-${props.modelValue}`,
      icon: 'exam',
      label: t('Spell check'),
      disabled: props.modelValue?.length === 0,
      inputData: {
        original_text: props.modelValue,
      },
      parser: response => response.correct_text,
    },
    {
      action: 'summarize_text',
      fetchingAction: `summarize_text-${props.modelValue}`,
      icon: 'arrows-in-line-vertical',
      label: t('Shorten'),
      disabled: props.modelValue?.length < 20,
      inputData: {
        original_text: props.modelValue,
      },
      parser: response => response.condensed_text,
    },
    {
      action: 'expand_text',
      fetchingAction: `expand_text-${props.modelValue}`,
      icon: 'arrows-out-line-vertical',
      label: t(props.modelValue?.length >= props.limitChars ? 'Disabled' : 'Expand'),
      disabled: props.modelValue?.length < 10 || props.modelValue?.length >= props.limitChars,
      inputData: {
        original_text: props.modelValue,
        limit: `${props.limitChars} characters`,
      },
      parser: response => response.expanded_text,
    },
  ];

  return [
    ...(props.customMagicActions || []).map(customMagicAction => ({
      type: 'custom',
      ...customMagicAction,
      icon: customMagicAction.icon || 'magic-wand',
      label: t(customMagicAction.label || 'Generate'),
    })),
    ...ownActions,
  ];
});

async function onClickMagicAction(actionId) {
  const activeFieldId = props.fieldId;

  emit('initAIRequest', actionId);

  const actionParams = magicActions.value.find(magicAction => magicAction.action === actionId);
  const aiResponse = await runSimpleAction(actionParams);
  const parsedResponse = actionParams.parser ? actionParams.parser(aiResponse) : aiResponse;

  emit('aiResponse', {
    action: actionId,
    parsedResponse,
    targetFieldId: activeFieldId,
  });
}

function showMessage(message) {
  if (tooltipRef.value) {
    tooltipRef.value.show(message, {top: 0, left: 20});
  }
}

defineExpose({
  showMessage,
});
</script>

<template>
  <Transition name="agent-button" appear>
    <div v-if="showing" class="flex rounded-full bg-white shadow-xl justify-end overflow-hidden">
      <GlueButton
        class="flex-shrink-0 [&_svg]:w-10 [&_svg]:px-2 [&_svg]:h-10"
        v-for="{action, fetchingAction, label, icon, disabled} in magicActions"
        :key="action"
        :tooltip="label"
        :icon="icon"
        :disabled="disabled"
        :size="ButtonSizes.Custom"
        :color="FormColors.Indigo"
        :border-style="FormBorderStyles.Straight"
        :fetching-action="fetchingAction || `runSimpleAction-${action}`"
        @mousedown="(ev) => {
          ev.preventDefault();
          ev.stopPropagation();
          onClickMagicAction(action);
        }"
      />
      <Tooltip
        ref="tooltipRef"
        :auto-hide="5000"
        :color="TooltipColors.Black"
      />
    </div>
  </Transition>
</template>

<style lang="postcss">
@keyframes agent-button {
  0% {
    max-width: 40px;
    transform: scale(0);
  }
  30% {
    max-width: 40px;
    transform: scale(0);
  }
  40% {
    max-width: 40px;
    transform: scale(1.3);
  }
  50% {
    max-width: 40px;
    transform: scale(1);
  }
  100% {
    max-width: 1000px;
  }
}

.agent-button {
  &-enter-active {
    animation: agent-button 1.57s;
  }

  &-leave-active {
    animation: agent-button 1.57s reverse;
  }
}
</style>