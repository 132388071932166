<script setup>
import { ref } from 'vue';

import useLanguages from '@/composables_NEW/useLanguages';

import Popup from '@/components_NEW/common/Popup.vue';
import GcEmailLink from '@/components_NEW/common/GcEmailLink.vue';

import { GCMailTypes, PopupSizes } from '@/constants/common';

defineProps({
  applicationId: String,
  sharePopup: Object,
});

const { t } = useLanguages();

const popupRef = ref();

function open() {
  popupRef.value.open();
}

function close() {
  popupRef.value.close();
}

defineExpose({
  open,
  close,
});
</script>

<template>
  <Popup
    ref="popupRef"
    :size="PopupSizes.Small"
  >
    <div class="flex flex-col items-start">
      <div class="text-xl flex items-center text-slate-800">
        {{ t('Get Quotes') }}
      </div>
      <div class="my-6 text-slate-700 text-sm flex flex-col gap-2">
        <p>{{ t('You can book time with us by using this link:') }}</p>
        <GcEmailLink :type="GCMailTypes.Calendly"/>
        <p>{{ t('or send us a request here and we’ll be in touch with you in 24 hours.') }}</p>
        <GcEmailLink :type="GCMailTypes.Help"/>
      </div>
    </div>
  </Popup>
</template>
