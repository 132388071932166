<script setup>
import { onMounted } from 'vue';
import { useRouter } from 'vue-router';

import useLanguages from '@/composables_NEW/useLanguages';

import Icon from '@/components_NEW/common/Icon.vue';

const emit = defineEmits(['click-button']);

const props = defineProps({
  icon: String,
  label: String,
  description: String,
  routeName: String,
  mountedAction: Function,
  action: Function,
  color: {
    type: String,
    default: 'bg-white',
  },
});

const router = useRouter();
const { t } = useLanguages();

onMounted(() => {
  if (props.mountedAction) {
    props.mountedAction();
  }
});
</script>

<template>
	<div
		class="
			relative z-10 px-4 py-3 flex gap-4 min-h-24 bg-slate-50 border-slate-100 border 
			hover:scale-105 transition-all cursor-pointer group hover:shadow-xl hover:z-20
		"
		@click="() => {
				if (action) {
          action();
				} else if (routeName) {
					router.push({
						name: routeName,
						params: {
							...router.currentRoute.value.params,
						},
					})
        } else {
					emit('click-button');
				}
			}"
		>
		<Icon
			class="flex-shrink-0 w-11 h-11 p-2 rounded-full"
			:class="[
				color,
			]"
			:icon="icon"
		/>
		<div class="flex flex-col">
			<span class="font-semibold">{{ t(label) }}</span>
			<span class="text-sm text-slate-500">{{ t(description) }}</span>
		</div>
		<Icon
			class="absolute bottom-1 right-1 w-8 h-8 bg-transparent group-hover:bg-white p-1 rounded-full"
			icon="arrow_right"
		/>
	</div>
</template>
