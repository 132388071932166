<script setup>
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';

import { useElementSize } from '@vueuse/core';

import useLanguages from '@/composables_NEW/useLanguages';
import useUsers from '@/composables_NEW/useUsers';

import Popup from './Popup.vue';

import { PopupSizes } from '@/constants/common';

const { t } = useLanguages();
const { setActiveUserCustomProps } = useUsers();

const chapters = {
  [t('Create New Project')]: 0,
  [t('Create Users')]: 95,
  [t('Create Use Cases')]: 123,
  [t('Create Scenarios')]: 195,
  [t('Create Technical Diagrams')]: 253,
};

const popupRef = ref();
const playerDom = ref();
const ytPlayer = ref();
const timer = ref();
const currentTime = ref();

const { width: playerWidth } = useElementSize(playerDom);

const playerHeight = computed(() => playerWidth.value ? (9*playerWidth.value)/16 : 320);
const activeChapter = computed(() => Object.values(chapters).filter(time => time <= currentTime.value).pop());

function open() {
  popupRef.value.open();
  timer.value = setInterval(() => {
    currentTime.value = ytPlayer.value?.getCurrentTime ? ytPlayer.value?.getCurrentTime() : 0.0;
  }, 1000);

  setActiveUserCustomProps({
    onBoardingPopupOpened: true,
  });
}

function close() {
  popupRef.value.close(); 
  clearInterval(timer.value);
}

onMounted(() => {
  const tag = document.createElement('script');
  const firstScriptTag = document.getElementsByTagName('script')[0];
  
  tag.src = 'https://www.youtube.com/player_api';
  firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
});

onUnmounted(() => {
  clearInterval(timer.value);
});

watch(playerDom, () => {
  if (playerDom.value && !ytPlayer.value) {
    // eslint-disable-next-line no-undef
    ytPlayer.value = new YT.Player('ytplayer');
  }
});

defineExpose({
  open,
  close,
});
</script>

<template>
  <Popup
    ref="popupRef"
    :label="t('Video Tutorial')"
    :size="PopupSizes.ExtraLarge"
  >
    <iframe
      class="mt-8"
      ref="playerDom"
      width="100%"
      :height="playerHeight"
      id="ytplayer"
      src="https://www.youtube.com/embed/kdMXs4s4bgo?enablejsapi=1&autoplay=1&rel=0"
      title="Video Tutorial"
      allowfullscreen
    />
    <div class="flex items-center border-b px-0 mt-4">
      <span class="text-lg font-medium pl-1">{{ t('Chapters') }}</span>
    </div>
    <div class="flex flex-col pl-6 gap-1 mt-2">
      <div
        class="list-item list-decimal hover:bg-gray-50 cursor-pointer"
        :class="[
          activeChapter === time ? 'bg-gray-100' : '',
        ]"
        v-for="(time, title) in chapters"
        :key="time"
        @click="() => {
          ytPlayer.seekTo(time);
          ytPlayer.playVideo();
        }"
      >
        {{ title }}
      </div>
    </div>
  </Popup>
</template>
