<script setup>
import { ref } from 'vue';

import useLanguages from '@/composables_NEW/useLanguages';

import Popup from '@/components_NEW/common/Popup.vue';
import Icon from '@/components_NEW/common/Icon.vue';
import GcEmailLink from '@/components_NEW/common/GcEmailLink.vue';

import { GCMailTypes, PopupSizes } from '@/constants/common';

defineProps({
  applicationId: String,
  sharePopup: Object,
});

const { t } = useLanguages();

const popupRef = ref();

function open() {
  popupRef.value.open();
}

function close() {
  popupRef.value.close();
}

defineExpose({
  open,
  close,
});
</script>

<template>
  <Popup
    ref="popupRef"
    :size="PopupSizes.Small"
  >
    <div class="flex flex-col items-start">
      <div class="text-xl flex items-center text-slate-800">
        {{ t('Oops, we are still building this...') }}
        <Icon icon="oops" class="w-20 [&_path]:stroke-none"/>
      </div>
      <div class="mt-10 text-slate-700 text-sm">
        {{ t('Below you can download an example of what you’ll soon be able to create.') }}
      </div>
      <div class="mt-4 inline-block text-sm mb-6">
        <span>{{ t('If you have any ideas of what else you would like to see, please send them to') }}</span>
        <GcEmailLink class="ml-1" :type="GCMailTypes.Design"/>
      </div>
      <!--<GlueButton
        :label="t('Download example')"
      />-->
    </div>
  </Popup>
</template>
