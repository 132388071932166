import { computed, readonly, ref, watch } from 'vue';
import { activeApplicationId } from '@/router_NEW';

import useArtifacts from '@/composables_NEW/useArtifacts';

import { ApplicationArtifactTypes, ArtifactTypes } from '@/constants/artifacts';

const { artifacts, getAllApplicationArtifacts } = useArtifacts();

const readOnlyMode = ref();
const lastCreatedApplicationId = ref();
const activeAppplicationFullLoaded = ref(false);

const fullActiveApplication = computed(() => ({
  ...activeApplication.value,
  ...ApplicationArtifactTypes.reduce((acc, artifactType) => {
    acc[artifactType] = artifacts[artifactType];
    return acc;
  }, {}),
}));

const activeApplication = computed(() => activeApplicationId.value &&  {
  ...artifacts[ArtifactTypes.Application][activeApplicationId.value],
});

async function getFullApplicationContent() {
  activeAppplicationFullLoaded.value = false;
  await Promise.all([
    getAllApplicationArtifacts(activeApplicationId.value),
  ]);
  activeAppplicationFullLoaded.value = true;
}

watch(activeApplicationId, (newVal, oldVal) => {
  if (newVal !== oldVal) {
    getFullApplicationContent();
  }
}, {immediate: true});

export default function useApplications() {
  return {
    readOnlyMode: readonly(readOnlyMode),
    lastCreatedApplicationId: readonly(lastCreatedApplicationId),
    activeApplication,
    fullActiveApplication,
    getFullApplicationContent,
    activeAppplicationFullLoaded,
  };
}
