<script setup>
import { ref, watch, onMounted, computed } from 'vue';
import { onClickOutside } from '@vueuse/core';

import IconX from '@/svg/x_icon.svg';

import { appendPx, isMobile } from '@/utils/utils';
import { TooltipColors, TooltipThemes } from '@/constants/forms';

const props = defineProps({
  autoHide: Number,
  message: String,
  color: {
    type: String,
    default: TooltipColors.Red,
  },
  autoShow: {
    type: Boolean,
    default: false,
  },
  closeButton: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['hidden', 'clickCloseButton']);

const rootDom = ref();
const messageDom = ref();
const isLoaded = ref();

const showing = ref(false);
const initHide = ref(false);
const hidden = ref(true);
const initShowTime = ref();

const messageToShow = ref();
const position = ref();

const showAtBottom = ref();
const rootStyle = ref();
const messageStyle = ref();

const animationName = computed(() => showAtBottom.value ? 'fade-tooltip-bottom' : 'fade-tooltip');

function hide() {
  initShowTime.value = 0;
  initHide.value = true;
  showing.value = false;
}

function show(text, targetPosition) {
  if (isMobile()) {
    return;
  }

  hidden.value = false;
  initHide.value = false;
  messageToShow.value = text || props.message;
  position.value = targetPosition;
  
  requestAnimationFrame(tick);
}

function tick(time) {
  if (!rootDom.value) {
    return;
  }

  const {
    width,
    height,
  } = messageDom.value?.getBoundingClientRect() || {};

  if (width && height) {  
    const {
      top,
      left,
      bottom,
    } = rootDom.value.getBoundingClientRect();
    
    const {
      top: positionTop,
      left: positionLeft,
    } = position.value || {};

    showAtBottom.value = top + positionTop - height - 8 < 20;
    
    const rootLeft = (left + positionLeft);
    const rootTop = (showAtBottom.value ? bottom + 10 : (top + positionTop));
    const messageLeft = Math.min((document.body.offsetWidth - width - 10) - rootLeft, -(width / 2));

    rootStyle.value = {
      top: appendPx(rootTop),
      left: appendPx(rootLeft),
    };
  
    messageStyle.value = {
      top: appendPx(-((showAtBottom.value ? 0 : height) + 10)),
      left: appendPx(rootLeft + messageLeft < 10 ? -10 : messageLeft),
    };
  
    if (!initShowTime.value) {
      initShowTime.value = time;
    }
  
    if (props.autoHide && time - initShowTime.value > props.autoHide) {
      hide();
    }
  }

  if (!initHide.value) {
    showing.value = true;
    requestAnimationFrame(tick);
  }
}

function onClickCloseButton() {
  hide();
  emit('clickCloseButton');
}

function onEndHideAnimation() {
  hidden.value = true;
  emit('hidden');
}

onClickOutside(rootDom, onClickCloseButton);

watch(() => props.message, () => {
  if (props.autoShow && props.message) {
    show(props.message);
  }
}, {immediate: true});

defineExpose({
  showing,
  show,
  hide,
});

onMounted(() => {
  isLoaded.value = true;
});
</script>

<template>
  <div
    class="absolute top-0 left-0 z-50 h-full"
    ref="rootDom"
  >
  </div>
  <Teleport to="#gc-teleports" v-if="isLoaded && !hidden">
    <Transition name="fade">
      <div
        v-if="!hidden && !!closeButton"
        class="fixed inset-0 pointer-events-auto bg-black bg-opacity-10"
      />
    </Transition>
    <transition
      :name="animationName"
      @after-leave="onEndHideAnimation"
    >
      <div 
        class="absolute z-50"
        :class="[
          closeButton ? 'pointer-events-auto' : 'pointer-events-none',
        ]"
        :style="rootStyle"
        v-show="showing"
      >
        <div
          class="absolute h-3 w-3 rotate-45 -left-1.5 origin-center -top-4"
          :class="TooltipThemes[color]"
        />
        <div
          ref="messageDom"
          class="absolute z-10 min-w-max"
          :class="TooltipThemes[color]"
          :style="messageStyle"
        >
          <IconX
            v-if="closeButton"
            class="w-3 h-3 absolute top-0 right-0 cursor-pointer stroke-white"
            @click="onClickCloseButton"
          />
          <div
            class="max-w-[400px] px-4 py-1 text-xs text-center rounded-sm shadow-sm select-text max-h-80 overflow-y-auto"
            v-html="messageToShow"
          />
        </div>
      </div>
    </transition>
  </Teleport>
</template>

<style lang="postcss">
@import '@/styles/fade-tooltip.css';
</style>
