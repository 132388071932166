<script setup>
import { ref, watch, onMounted } from 'vue';

import Typewriter from 'typewriter-effect/dist/core';

const props = defineProps({
  text: String,
  delay: {
    type: Number,
    default: 20,
  },
  velocity: {
    type: Number,
    default: 40,
  },
});

const typeWriter = ref();
const textDom = ref();

function initTypewriter() {
  if (typeWriter.value) {
    typeWriter.value.stop();
  }

  typeWriter.value = new Typewriter(textDom.value, {
    delay: props.delay,
  });

  typeWriter.value
    .typeString(props.text)
    .start();
}

onMounted(() => {
  initTypewriter();
});

watch(() => props.text, () => {
  initTypewriter();
});
</script>

<template>
  <div ref="textDom"/>
</template>

<style lang="postcss">
  .blink {
    animation: blink .5s .4s infinite;
  }

  @keyframes blink {
    0%, 45% {
      border-color: transparent;
    }
    50%, 100% {
      border-color: black;
    }
  }
</style>