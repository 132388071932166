import { createApp } from 'vue';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';

import { router } from './router_NEW';

import useAnalytics from './composables_NEW/useAnalytics';

import { isProd } from './constants/common';

import Main from './Main_NEW.vue';

import './styles/index.css';

// Verbose logging flag
const LOG_VERBOSE = false;

const { initAnalytics } = useAnalytics();

const app = createApp(Main);

// Initialize Pinia
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

// Initialize analytics
const gtmObj = initAnalytics({
  appObj: app,
  isProd,
  router,
});


app.use(pinia);
app.use(router);
app.use(gtmObj);

if (LOG_VERBOSE) {
  console.log('🍍 Pinia initialized with persistence');
  console.log('🔄 Router initialized');
  console.log('📊 Analytics initialized');
}

app.mount('#main');
