import { onBeforeUnmount, watch } from 'vue';

//Stop error resizeObserver
const debounce = (callback, delay) => {
  let tid;
  return function (...args) {
    const ctx = self;
    tid && clearTimeout(tid);
    tid = setTimeout(() => {
      callback.apply(ctx, args);
    }, delay);
  };
};
  
const _ = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ {
  constructor(callback) {
    callback = debounce (callback, 20);
    super(callback);
  }
};

export default function useResizeObserver(domElementRef, callback) {
  let observer = new ResizeObserver(entries => {
    window.requestAnimationFrame(() => {
      if (!Array.isArray(entries) || !entries.length) {
        return;
      }
      callback();
    });
  });

  watch(domElementRef, () => {
    if (domElementRef.value) {
      observer.observe(domElementRef.value);
    }
  });

  onBeforeUnmount(() => {
    observer.disconnect();
    observer = null;
  });

  return {
    observer,
  };
}
