<script setup>
import { ref, computed, watch, onMounted } from 'vue';
import { useRouter } from 'vue-router';

import useLanguages from '@/composables_NEW/useLanguages';
import useUsers from '@/composables_NEW/useUsers';
import useWorkspaces from '@/composables_NEW/useWorkspaces';

import Popup from './Popup.vue';
import GlueTable from './GlueTable.vue';

import { InvitationTypes, PopupSizes, RouteNames, SharedOrgData } from '@/constants/common';
import { TableColumnTypes } from '@/constants/tables';
import { FormColors } from '@/constants/forms';

const { t } = useLanguages();
const { activeUser, acceptInvitation, declineInvitation, listActiveUserPendingInvitations } = useUsers();
const { workspaces, getWorkspacesList } = useWorkspaces();

const router = useRouter();

const popupRef = ref();

const pendingInvitations = computed(() => activeUser.value?.invitations);

const invitationsList = computed(() => {
  return pendingInvitations.value.map(invitation => ({
    ...invitation,
    message: invitation.message,
    actions: [{
      tooltip: t('Dismiss'),
      icon: 'x_icon',
      color: FormColors.Red,
      fetchingAction: `declineInvitation-${invitation.invitation_id}`,
      action: async () => {
        await declineInvitation(invitation.invitation_id);
        await listActiveUserPendingInvitations();
        
        if(!pendingInvitations.value.length) {
          close();
        }
      },
    }, {
      tooltip: t('Accept'),
      icon: 'check',
      color: FormColors.Green,
      fetchingAction: `acceptInvitation-${invitation.invitation_id}`,
      action: async () => {
        await acceptInvitation(invitation.invitation_id, false);
        await listActiveUserPendingInvitations();

        if (invitation.type === InvitationTypes.OrganizationInvitation) {
          await getWorkspacesList();
          
          if (!pendingInvitations.value.length) {
            router.push({
              name: RouteNames.Workspace,
              params: {
                org: workspaces.value[invitation.organization_id]?.slug,
              },
            });
          }
        } else if (invitation.type === InvitationTypes.ProjectInvitation) {
          if (!pendingInvitations.value.length) {
            router.push({
              name: RouteNames.Application,
              params: {
                org: SharedOrgData.slug,
                appId: invitation.application_id,
              },
            });
          }
        }
      },
    }],
  }));
});

function open() {
  popupRef.value.open();
}

function close() {
  popupRef.value.close();
}

watch(pendingInvitations, () => {
  if(pendingInvitations.value?.length) {
    open();
  } else {
    close();
  }
});

onMounted(() => {

});
defineExpose({
  open,
  close,
});
</script>

<template>
  <Popup ref="popupRef" :label="t('Pending Invitations')" :size="PopupSizes.Medium">
    <GlueTable
      class="w-full mt-8"
      :data="invitationsList"
      :columns="[{
        title: t('Invitation'),
        field: 'message',
        minWidth: 'min-content'
      }, {
        title: t('Actions'),
        field: 'actions',
        type: TableColumnTypes.Actions,
      }]"
    />
  </Popup>
</template>
