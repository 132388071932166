import { ref, computed, readonly, watch } from 'vue';
import { router } from '@/router_NEW';

import { initLibrary } from '@gluecharm/glue-charm-library';

import useFetching from '@/composables_NEW/useFetching';
import useAnalytics from '@/composables_NEW/useAnalytics';
import usePersistentData from '@/composables_NEW/usePersistentData';

import { deepEqualData, parseJwt } from '@/utils/utils';
import { RouteNames } from '@/constants/common';

const persistentId = 'gc-tokens';

const { savePersistentData, getPersistentData, removeAllPersistentData } = usePersistentData();
const { captureMessage } = useAnalytics();

const {
  addFetchingAction, setFetchingActionDone, 
  addFetchingError, clearAllFetchingData,
} = useFetching();

const gluecharmMethods = ref();
const gluecharmFetch = ref();
const libraryInitialized = ref();
const error500 = ref();
const tokens = ref();
const refreshTokenExpirationTimeout = ref();
const accessTokenExpirationTimeout = ref();

const accessToken = computed(() => tokens.value?.access_token);
const refreshToken = computed(() => tokens.value?.refresh_token);
const isUserAuthenticated = computed(() => !!accessToken.value);

const parsedTokens = computed(() => ({
  accessToken: parseJwt(accessToken.value),
  refreshToken: parseJwt(refreshToken.value),
}));

function getSecondsToRefreshExpiration() {
  return Math.floor(parsedTokens.value.refreshToken.exp - (Date.now()/1000));
} 

function getSecondsToAccessTokenExpiration() {
  return Math.floor(parsedTokens.value.accessToken.exp - (Date.now()/1000));
}

async function initializeLibrary() {
  const {fetch, methodsInfo} = await initLibrary();
  const localTokens = await getPersistentData(persistentId);

  gluecharmFetch.value = fetch;
  gluecharmMethods.value = methodsInfo;

  libraryInitialized.value = true;

  if (localTokens) {
    setTokens(localTokens);
  }
}

function setTokens(newTokens) {
  try {
    const areValidTokens = (
      !!newTokens.access_token 
      && !!newTokens.refresh_token
      && !!parseJwt(newTokens.access_token)
      && !!parseJwt(newTokens.refresh_token)
    );

    if (areValidTokens && !deepEqualData(tokens.value, newTokens)) {
      tokens.value = newTokens;

      if (accessTokenExpirationTimeout.value) {
        clearTimeout(accessTokenExpirationTimeout.value);
      }
      if (refreshTokenExpirationTimeout.value) {
        clearTimeout(refreshTokenExpirationTimeout.value);
      }
    
      accessTokenExpirationTimeout.value = setTimeout(() => {
        refreshAccessToken();
      }, (getSecondsToAccessTokenExpiration() - 15) * 1000);

      refreshTokenExpirationTimeout.value = setTimeout(() => {
        logout();
      }, (getSecondsToRefreshExpiration() - 3) * 1000);
    
      savePersistentData(persistentId, newTokens);
      return true;
    }
  } catch (error) {
    return false;
  }
}

async function logout() {
  tokens.value = null;

  router.push({
    name: RouteNames.Login,
  });
}

async function refreshAccessToken() {
  const response = await fetchGlueCharmMethod('token-manager/refresh', 'refreshAccessToken', {
    token: refreshToken.value,
  });

  if (response) {
    setTokens(response);
  }
}

async function fetchGlueCharmMethod(methodPathString, fetchingName, params = {}, configIdRef='default', attemps = 0) {
  const methodPathParts = methodPathString.split(':');
  const configId = methodPathParts.length > 1 ? methodPathParts[0] : configIdRef;
  const methodPath = methodPathParts.length > 1 ? methodPathParts[1] : methodPathString;
  
  addFetchingAction(fetchingName);

  if (!libraryInitialized.value) {
    const success = await new Promise((resolve) => {
      const stopWatch = watch(libraryInitialized, () => {
        if (libraryInitialized.value) {
          stopWatch();
          resolve(true);
        }
      }, {immediate: true});
      setTimeout(() => {
        if (!libraryInitialized.value) {
          resolve(false);
        }
      }, 5000);
    });

    if (!success) {
      addFetchingError(fetchingName, 'Library not initialized');
      return false;
    }
  }

  const method = gluecharmMethods.value[configId][methodPath];

  const parsedParams = {
    ...params,
  };

  if (method?.params.find(param => param.name === 'token')) {
    parsedParams.token = parsedParams.token || accessToken.value;
  }
  
  try {
    const missingParam = method?.params.find(param => param.required && (parsedParams[param.name] === undefined || parsedParams[param.name] === null));

    if (missingParam) {
      throw new Error(`missing required parameter for ${methodPath}: ${missingParam.name}`);
    }

    const response = await gluecharmFetch.value(methodPath, parsedParams, configId);

    if (response.status_operation === 'KO') {
      const responseKey = response.error.toLowerCase();
      const errorCode = response.error_code;
      const errorLiteral = `${responseKey} (${methodPath}-${response.job_id || 'no-jobId'})`;

      if (errorCode === '001' || response.status === 500) {
        if (attemps < 6) {
          captureMessage(`retrying ${methodPath} after error 001: ${responseKey}`);
          await new Promise((resolve) => setTimeout(resolve, 1500*(attemps+1)));
          return fetchGlueCharmMethod(methodPath, fetchingName, params, configId, attemps+1);
        } else {
          captureMessage(`reached max attemps for ${methodPath} after error 001 with params: ${JSON.stringify(params)}`);
          throw new Error('I\'m working on your request right now. Please check back in a few minutes. Thanks for your patience!');
        }
      } else if (responseKey.toLowerCase() === 'invalid token') {
        error500.value = errorLiteral;
        logout();
      } else if (responseKey.toLowerCase() === 'token is expired') {
        const refreshed = await refreshAccessToken();
        if (refreshed) {
          return fetchGlueCharmMethod(methodPath, fetchingName, params, configId, attemps+1);
        }
      }

      captureMessage(errorLiteral);
      throw new Error(errorLiteral);
    }
    
    setFetchingActionDone(fetchingName);
    return response.data;
  } catch (err) {
    addFetchingError(fetchingName, err);
    return false;
  }
}

watch(isUserAuthenticated, async () => {
  if (!isUserAuthenticated.value) {
    clearAllFetchingData();
    await removeAllPersistentData();
    logout();
  }
});

export default function useGlueCharmServices () {
  return {
    error500,
    accessToken: readonly(accessToken),
    gluecharmMethods: readonly(gluecharmMethods),
    libraryInitialized: readonly(libraryInitialized),
    isUserAuthenticated,
    initializeLibrary,
    fetchGlueCharmMethod,
    logout,
    setTokens,
  };
}
