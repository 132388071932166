import { computed, readonly, ref, watch } from 'vue';

import useGlueCharmServices from '@/composables_NEW/useGlueCharmServices';
import useAnalytics from '@/composables_NEW/useAnalytics';

import { parseJwt } from '@/utils/utils';
import { AnalyticEvents } from '@/constants/common';

const { accessToken, isUserAuthenticated, setTokens, fetchGlueCharmMethod } = useGlueCharmServices();
const { identifyUser, trackEvent } = useAnalytics();

const activeUserId = ref();
const activeUserPendingInvitations = ref();
const activeUserCustomProps = ref({});

const users = ref({});
const applicationUsers = ref({});

const activeUser = computed(() => {
  if (!users.value[activeUserId.value]) {
    return false;
  }
  
  const memberships = {};
  if (accessToken.value) {
    const userMemberships = parseJwt(accessToken.value)?.request_owner?.memberships || [];
    userMemberships.forEach(membership => memberships[membership.organization_id] = {
      role: membership.role,
      isOwner: membership.is_owner,
    });
  }

  return {
    ...users.value?.[activeUserId.value],
    memberships,
    invitations: activeUserPendingInvitations.value,
    customProps: activeUserCustomProps.value,
    isGCTeamMember: memberships?.['2bc6575a-4cb9-4ce0-b1fd-1d1bc4644f25']?.isOwner,
  };
});

function setActiveUserCustomProps(customProps = {}) {
  activeUserCustomProps.value = {
    ...(activeUserCustomProps.value || {}),
    ...customProps,
  };
}

async function getActiveUser() {
  const response = await fetchGlueCharmMethod('user-manager/get', 'getActiveUser');

  if (response) {
    users.value[response.id] = response;
    activeUserId.value = response.id;
    activeUserCustomProps.value = {};
    
    identifyUser({
      id: response.id,
      email: response.email,
    });

    return true;
  }

  return false;
}

async function getUser(userId) {
  const response = await fetchGlueCharmMethod('user-manager/toolkit-get', `getUser-${userId}`, {
    id: userId,
  });

  if (response) {
    users.value[response.id] = response;
    return true;
  }
  
  return false;
}

async function listByPartialEmail(partial_email) {
  const response = await fetchGlueCharmMethod('user-manager/list-users-by-partial-email', 'listByPartialEmail', {
    partial_email,
  });
  
  if (response?.users) {
    return response.users;
  }

  return false;
}

async function login({email, password}) {
  const response = await fetchGlueCharmMethod('user-manager/login', 'login', {
    email,
    password,
  });
  
  if (response) {
    setTokens(response);
    return true;
  }

  return false;
}

async function forgotPassword({email}) {
  const response = await fetchGlueCharmMethod('user-manager/forgot-password', 'forgotPassword', {
    email,
  });
  
  return !!response;
}

async function resetPassword({password, token}) {
  const response = await fetchGlueCharmMethod('user-manager/reset-password', 'resetPassword', {
    password,
    token,
  });
  
  return !!response;
}

async function register({email, id, name, password}) {
  const response = await fetchGlueCharmMethod('user-manager/register', 'register', {
    email,
    id,
    name,
    password,
  });
  
  if (response) {
    setTokens(response);
    trackEvent(AnalyticEvents.Register, {
      userEmail: email,
      userId: id,
    });

    return true;
  }

  return false;
}

async function registerUserByInvitation({email, hash, name, password}) {
  const response = await fetchGlueCharmMethod('user-manager/register-user-by-invitation', 'register', {
    email,
    hash,
    name,
    password,
  });
  
  return !!response;
}

async function listUsersByApplication(applicationId) {
  const response = await fetchGlueCharmMethod('user-manager/list-users-with-shared-application', `listUsersByApplication-${applicationId}`, {
    application_id: applicationId,
  });
  
  if (response?.users){
    applicationUsers.value[applicationId] = response.users;
  }
  
  return !!response;
}

async function listActiveUserPendingInvitations() {
  const response = await fetchGlueCharmMethod('invitation-manager/list-pending-invitations', 'listActiveUserPendingInvitations', {});
  
  if (response?.invitations){
    activeUserPendingInvitations.value = response.invitations.map(invitation => {
      const hashData = parseJwt(invitation.hash);

      return {
        ...invitation,
        message: hashData.metadata?.message,
        application_id: hashData.metadata?.application_id,
      };
    });
  }
  
  return !!response;
}

async function declineInvitation(invitationId) {
  const response = await fetchGlueCharmMethod('invitation-manager/accept-or-decline-invitation', `declineInvitation-${invitationId}`, {
    invitation_id: invitationId,
    status: 'declined',
  });

  return !!response;
}

async function acceptInvitation(invitationId) {
  const response = await fetchGlueCharmMethod('invitation-manager/accept-or-decline-invitation', `acceptInvitation-${invitationId}`, {
    invitation_id: invitationId,
    status: 'accepted',
  });
  
  if (response){
    setTokens(response);
  }
  
  return !!response;
}

watch(isUserAuthenticated, () => {
  if (!isUserAuthenticated.value) {
    users.value = {};
    activeUserId.value = null;
  } else {
    getActiveUser();
    listActiveUserPendingInvitations();
  }
});

export default function useUsers() {
  return {
    activeUserId: readonly(activeUserId),
    users: readonly(users),
    applicationUsers: readonly(applicationUsers),
    activeUser: readonly(activeUser),
    getActiveUser,
    getUser,
    setActiveUserCustomProps,
    listByPartialEmail,
    listUsersByApplication,
    login,
    forgotPassword,
    resetPassword,
    register,
    registerUserByInvitation,
    listActiveUserPendingInvitations,
    acceptInvitation,
    declineInvitation,
  };
}
