export default {
  socketUrl: 'https://broadcaster.gluecharm.info:3000/',
  baseUrl: 'https://7xwdpsrfgg.execute-api.eu-west-1.amazonaws.com/dev',
  serviceId: 'ST-001',
  isGC1: true,
};

export const gluex = {
  socketUrl: 'https://broadcaster-extended.gluecharm.info:3000/',
  baseUrl: 'https://bdr234rbud.execute-api.eu-west-1.amazonaws.com/dev',
  serviceId: 'st-7',
  isGC1: false,
};
