export const FlowTypes = {
  HumanFlow: 'human_flow',
  InternalMessage: 'internal_flow',
  BackendStep: 'backend_step',
};

export const FlowTypeLiterals = {
  [FlowTypes.HumanFlow]: 'Front-End Diagram',
  [FlowTypes.InternalMessage]: 'Back-End Diagram',
};

export const FlowCardColors = {
  Pink: { background: 'bg-cyan-300', border: 'border-cyan-300'},
  Violet: { background: 'bg-violet-300', border: 'border-violet-300'},
  Green: { background: 'bg-green-300', border: 'border-green-300'},
  Blue: { background: 'bg-blue-300', border: 'border-blue-300'},
  Yellow: { background: 'bg-yellow-300', border: 'border-yellow-300'},
  Orange: { background: 'bg-orange-300', border: 'border-orange-300'},
  Gray: { background: 'bg-gray-300', border: 'border-gray-300'},
};

export const FlowCardStates = {
  Undefined: 'undefined',
  Created: 'created',
  Loading: 'loading',
};

export const FlowNodeTypes = {
  ConnectorUser: 'connector_user',
  ConnectorBroadcaster: 'connector_dispatcher_world', //TODO: change this id in next version
  ConnectorBroker: 'connector_broker',
  ConnectorBrokerWorld: 'connector_broker_world',
  EventConnector: 'event_connector',
  ApplicationMessage: 'application_message',
  RequestMessage: 'request_message',
  ResponseMessage: 'response_message',
  AcknowledgementMessage: 'acknowledgement_message',
  HumanInteraction: 'human_interaction',
  ApiRequestMessage: 'api_request_message',
  FrontendMethod: 'frontend_method',
  BackendMethod: 'backend_method',
  FrontendMethodMessage: 'frontend_method_message',
  BackendMethodMessage: 'backend_method_message',
};

export const FlowNodeTypeCardColors = {
  'undefined': FlowCardColors.Gray,
  [FlowNodeTypes.ResponseMessage]: FlowCardColors.Yellow,
  [FlowNodeTypes.ApplicationMessage]: FlowCardColors.Orange,
  [FlowNodeTypes.RequestMessage]: FlowCardColors.Green,
};

export const FlowNodeTypeLiteral = {
  [FlowNodeTypes.ApplicationMessage]: 'Application Event',
  [FlowNodeTypes.RequestMessage]: 'Request Event',
  [FlowNodeTypes.ResponseMessage]: 'Response Event',
  [FlowNodeTypes.AcknowledgementMessage]: 'Acknowledgement Event',
  [FlowNodeTypes.HumanInteraction]: 'Human Interaction',
  [FlowNodeTypes.FrontendMethod]: 'Front-End Method',
  [FlowNodeTypes.BackendMethod]: 'Back-End Method',
};

export const ConnectorNodeTypes = [
  FlowNodeTypes.ConnectorUser, FlowNodeTypes.ConnectorBroadcaster,
  FlowNodeTypes.ConnectorBroker, FlowNodeTypes.ConnectorBrokerWorld,
];

export const ServiceNodeTypes = [FlowNodeTypes.FrontendMethod, FlowNodeTypes.BackendMethod];

export const MessageNodeTypes = [
  FlowNodeTypes.HumanInteraction, FlowNodeTypes.RequestMessage, FlowNodeTypes.ResponseMessage,
  FlowNodeTypes.ApiRequestMessage, FlowNodeTypes.ApplicationMessage,
];

export const MethodMessageNodeTypes = [
  FlowNodeTypes.BackendMethodMessage, FlowNodeTypes.FrontendMethodMessage,
];

export const InternalMessageNodeTypes = [
  FlowNodeTypes.AcknowledgementMessage, FlowNodeTypes.ResponseMessage,
];

export const ConnectorIcons = {
  [FlowNodeTypes.ConnectorUser]: 'user_solid',
  [FlowNodeTypes.ConnectorBroadcaster]: 'public_broadcaster',
  [FlowNodeTypes.ConnectorBroker]: 'broker_orchestrator',
};

export const ConnectorLiterals = {
  [FlowNodeTypes.ConnectorBroadcaster]: 'Broadcaster',
  [FlowNodeTypes.ConnectorBroker]: 'Broker',
};

export const FlowEdgeTypes = {
  Line: 'line',
  BottomLeft: 'bottomLeft',
  BottomRight: 'bottomRight',
};

export const FlowEdgeArrowTypes = {
  Left: 'left',
  Right: 'right',
  Double: 'double',
};

export const FlowTypeConstructor = {
  [FlowTypes.HumanFlow]: {
    nodes: [
      { type: FlowNodeTypes.ConnectorUser, position: [1, 1] },
      { id: true, type: FlowNodeTypes.HumanInteraction, position: [2, 1] },
      { id: true, type: FlowNodeTypes.FrontendMethod, position: [3, 1] },
      { type: FlowNodeTypes.FrontendMethodMessage, position: [4, 1] },
      { type: FlowNodeTypes.AcknowledgementMessage, position: [4, 2] },
      { type: FlowNodeTypes.ResponseMessage, position: [4, 3] },
      { type: FlowNodeTypes.ConnectorBroadcaster, position: [5, 1] },
      { type: FlowNodeTypes.ConnectorBroadcaster, position: [5, 3] },
      { type: FlowNodeTypes.ConnectorBroker, position: [6, 1] },
      { type: FlowNodeTypes.ConnectorBroker, position: [6, 3] },
    ],
    edges: [
      [[1, 1], [2, 1]], [[2, 1], [3, 1]], [[3, 1], [4, 1]], [[4, 1], [5, 1]], [[5, 1], [6, 1]],
      [[5, 1], [4, 2]], [[4, 2], [3, 1]],
      [[6, 3], [5, 3]], [[5, 3], [4, 3]], [[4, 3], [3, 1]],
    ],
  },
  [FlowTypes.InternalMessage]: {
    nodes: [
      { type: FlowNodeTypes.ConnectorBroker, position: [1, 1] },
      { type: FlowNodeTypes.ApiRequestMessage, position: [2, 1] },
      { id: true, type: FlowNodeTypes.BackendMethod, position: [3, 1] },
    ],
    edges: [
      [[1, 1], [2, 1]], [[2, 1], [3, 1]],
    ],
  },
  [FlowTypes.BackendStep]: {
    nodes: [
      { type: FlowNodeTypes.BackendMethodMessage, position: [1, 1] },
      { type: FlowNodeTypes.BackendMethod, position: [2, 1] },
    ],
    edges: [
      [[1, 1], [2, 1]],
    ],
  },
};

export const HumanFlowGenerationSteps = {
  HumanInteraction: 'Generating Human Interaction Event',
  Method: 'Generating API Method',
  Request: 'Generating Request Event',
};
