<script setup>
import { ref } from 'vue';

import useLanguages from '@/composables_NEW/useLanguages';

import GlueButton from './GlueButton.vue';
import Popup from '@/components_NEW/common/Popup.vue';
import PopupHeader from '@/components_NEW/common/PopupHeader.vue';

import { FormBorderStyles, FormStyles } from '@/constants/forms';

const { t } = useLanguages();

defineProps({
  dataType: String,
  message: String,
});

const emit = defineEmits(['confirm', 'refuse']);

const popupRef = ref();

function hide () {
  popupRef.value.openClose();
}

function show() {
  popupRef.value.openClose();
}

function onClickConfirmButton() {
  emit('confirm');
  hide();
}

function onClickCancelButton() {
  emit('cancel');
  hide();
}

defineExpose({
  show,
});
</script>

<template>
  <Popup ref="popupRef" :avoidOutsideClose="true" :label="t('Delete confirmation')">
    <PopupHeader icon="trash"
      :title="`${t('Delete')} ${dataType ? dataType : t('item')}`"
      :description="[message]"
    />
    <div class="flex-shrink mt-8 flex gap-4 items-center justify-center">
      <GlueButton
        :label="t('No, keep it')"
        :style="FormStyles.Outline"
        :border-style="FormBorderStyles.Rounded"
        @clickButton="onClickCancelButton"
      />
      <GlueButton
        :label="t('Yes, delete')"
        :border-style="FormBorderStyles.Rounded"
        @clickButton="onClickConfirmButton"
      />
    </div>
  </Popup>
</template>
