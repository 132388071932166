import * as xlsx from 'xlsx';

export function convertToXlsxCsv(jsonData, filename, fileType) {
  const workbook = xlsx.utils.book_new();
  const sheet = xlsx.utils.json_to_sheet(jsonData);

  xlsx.utils.book_append_sheet(workbook, sheet, 'Sheet 1');

  if (fileType === 'xlsx') {
    xlsx.writeFile(workbook, `${filename}.xlsx`);
  } else if (fileType === 'csv') {
    const csvData = xlsx.utils.sheet_to_csv(sheet, { FS: ';' });
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${filename}.csv`;
    a.click();
  }
}
