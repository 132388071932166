import { ref, readonly } from 'vue';

import { getErrorMessage } from '@/utils/utils';

const fetchingActions = ref(new Set());
const fetchingActionsDone = ref(new Set());
const fetchingErrors = ref({});

function updateSets() {
  fetchingActionsDone.value = new Set([...fetchingActionsDone.value]);
  fetchingActions.value = new Set([...fetchingActions.value]);
}

function addFetchingError(action, error) {
  fetchingErrors.value[action] = getErrorMessage(error);
  fetchingActions.value.delete(action);
  updateSets();
}

function removeFetchingError(action) {
  if (fetchingErrors.value[action]) {
    const errorsCopy = {
      ...fetchingErrors.value,
    };
  
    delete errorsCopy[action];
    fetchingErrors.value = errorsCopy;
  }
}

function addFetchingAction(action) {
  removeFetchingError(action);
  fetchingActionsDone.value.delete(action);
  fetchingActions.value.add(action);
  updateSets();
}

function setFetchingActionDone(action) {
  fetchingActions.value.delete(action);
  fetchingActionsDone.value.add(action);
  updateSets();
}

function clearFetchingErrors() {
  fetchingErrors.value = {};
}

function clearAllFetchingData() {
  fetchingActions.value = new Set();
  fetchingActionsDone.value = new Set();
  fetchingErrors.value = {};
}

export default function useFetching() {
  return {
    fetchingActions: readonly(fetchingActions),
    fetchingActionsDone: readonly(fetchingActionsDone),
    fetchingErrors: readonly(fetchingErrors),
    addFetchingAction,
    setFetchingActionDone,
    addFetchingError,
    removeFetchingError,
    clearAllFetchingData,
    clearFetchingErrors,
  };
}
