<script setup>
import { ref, computed, onMounted } from 'vue';

import GlueButton from '@/components_NEW/formFields/GlueButton.vue';

import { PopupSizes } from '@/constants/common';
import { isMobile } from '@/utils/utils';

const emit = defineEmits(['close']);

const props = defineProps({
  label: String,
  avoidOutsideClose: Boolean,
  hideCloseButton: Boolean,
  noPadding: Boolean,
  icon: String,
  isSidebar: Boolean,
  size: {
    type: String,
    default: PopupSizes.Small,
  },
});

const opened = ref();
const inited = ref();

const popupStyle = computed(() => ({
  width: props.size,
}));

function openClose() {
  if (opened.value) {
    close();
  } else {
    open();
  }
}

function open() {
  opened.value = true;
}

function close() {
  opened.value = false;
  emit('close');
}

onMounted(() => {
  inited.value = true;
});

defineExpose({
  opened,
  openClose,
  open,
  close,
});
</script>

<template>
  <Teleport to="#gc-teleports" v-if="inited">
    <Transition name="fade">
      <div
        v-if="opened"
        class="absolute z-40 inset-0 bg-black bg-opacity-30 flex items-center justify-center md:py-2"
      >
        <div
          class="absolute inset-0 pointer-events-auto"
          :class="[
            !avoidOutsideClose && 'cursor-pointer'
          ]"
          @click="() => {
            !avoidOutsideClose && openClose()
          }"
        />
        <Transition :name="isSidebar ? 'fade-from-right' : 'fade-from-bottom'" appear>
          <div
            class="bg-white flex flex-col min-w-[320px] max-w-full max-h-full pointer-events-auto"
            :class="[
              isMobile() || isSidebar ? 'h-full rounded-none' : 'h-auto rounded-2xl',
              isSidebar ? 'absolute top-0 right-0 h-full z-20 rounded-none' : 'relative',
            ]"
            :style="popupStyle"
          >
            <div class="absolute left-0 top-2 flex gap-2 w-full justify-end px-2">
              <div class="mr-auto">
                <slot name="buttons"/>
              </div>
              <GlueButton
                v-if="!hideCloseButton"
                icon="x_icon"
                @clickButton="openClose"
              />
            </div>
            <div class="w-full flex flex-col max-h-screen overflow-y-auto"
              :class="[
                !noPadding && 'p-8 pt-12 pb-10'
              ]"
            >           
              <slot />
            </div>
          </div>
        </Transition>
      </div> 
    </Transition>
  </Teleport>
</template>

<style lang="postcss">
  @import "@/styles/fade-from-bottom.css";
  @import "@/styles/fade-from-right.css";
</style>
