
<script setup>
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import Icon from '@/components_NEW/common/Icon.vue';

const router = useRouter();

const props = defineProps({
  label: String,
  icon: String,
  isClosed: Boolean,
  link: Object,
  dynamicLink: Function,
  action: Function,
  options: Object,
  rightSpace: Boolean,
});

const isButton = computed(() => props.link || props.action || props.options);
const isActive = computed(() => router.currentRoute.value?.name === props.link?.name);

function onClickItem(ev) {
  ev.preventDefault();
  ev.stopImmediatePropagation();
  if (props.action) {
    props.action();
  } else if (props.link) {
    router.push(props.link);
  }
}
</script>

<template>
  <div
    class="relative flex items-center gap-1 p-1 group w-full"
    :class="[
      isButton && !isActive && 'cursor-pointer text-slate-700 hover:bg-indigo-50 hover:text-indigo-700',
      isButton && isActive && 'text-indigo-700 bg-indigo-50',
    ]"
    @click="onClickItem"
  >
    <Icon
      v-if="icon"
      class="flex-shrink-0 pointer-events-none w-6 h-6"
      :class="[
        isActive && 'text-indigo-700',
      ]"
      :icon="icon"
    /> 
    <div class="flex-grow pointer-events-none">
      <div
        v-if="isClosed"
        class="transition-all absolute top-2 text-xs left-11 p-0.5 group-hover:px-1 px-0 bg-indigo-900 rounded text-white font-light group-hover:max-w-40 max-w-0 truncate"
      >
        {{ label }}
      </div>
      <div
        v-else
        class="absolute top-2 text-xs truncate left-8 font-semibold"
        :class="[
          rightSpace ? 'w-28' : 'w-32'
        ]"
      >
        {{ label }} 
      </div>
    </div>
  </div>
</template>
