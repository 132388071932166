import { readonly, ref, watch } from 'vue';

import useGlueCharmServices from '@/composables_NEW/useGlueCharmServices';

const { isUserAuthenticated, fetchGlueCharmMethod } = useGlueCharmServices();

const members = ref([]);
const invitations = ref([]);

function setOrganizationMembers(orgId, newMembers) {
  if (newMembers) {
    members.value = newMembers;
    return true;
  }
}

function setOrganizationInvitations(orgId, newInvitations) {
  if (newInvitations) {
    invitations.value = newInvitations;
    return true;
  }
}

async function getMembersList(organization_id) {
  const response = await fetchGlueCharmMethod('member-manager/list-members-by-organization-id', 'getMembersList', {
    organization_id,
  });
  
  if (response.members) {
    members.value = response.members;
    return true;
  }
  return false;
}

async function updateMember({id, role, is_owner}) {
  const response = await fetchGlueCharmMethod('member-manager/update-member', 'updateMember', {
    id,
    role,
    is_owner,
  });
  
  if (response) {
    members.value = members.value.map(member => ({
      ...member,
      member_role: member.member_id === id ? role : member.member_role,
      is_owner: member.member_id === id ? is_owner : member.is_owner,
    }));
    return true;
  }
  return false;
}

async function deleteMember({id}) {
  const response = await fetchGlueCharmMethod('member-manager/delete-member', `deleteMember-${id}`, {
    id,
  });
  
  if (response) {
    members.value = (members.value || []).filter(member => member.member_id !== id);

    return true;
  }
  return false;
}

async function createInvitation({email, invitation_id, is_owner, organization_id, role, type, user_id}) {
  const response = await fetchGlueCharmMethod('invitation-manager/create-invitation', 'createInvitation', {
    invitation_id,
    organization_id,
    user_id,
    email,
    is_owner,
    role,
    type,
  });
  
  return !!response;
}

async function createApplicationInvitation({invitation_id, organization_id, application_id, user_id, email, type, application_role}) {
  const response = await fetchGlueCharmMethod('invitation-manager/create-application-invitation', 'createApplicationInvitation', {
    invitation_id,
    organization_id,
    application_id,
    user_id,
    email,
    type,
    application_role,
  });
  
  return !!response;
}

async function listUnacceptedInvitations(organization_id) {
  const response = await fetchGlueCharmMethod('invitation-manager/list-unaccepted-invitations', 'listUnacceptedInvitations', {
    organization_id,
  });
  
  if (response?.invitations) {
    invitations.value = response.invitations;
    return true;
  }
  return false;
}

watch(isUserAuthenticated, () => {
  if (!isUserAuthenticated.value) {
    members.value = [];
    invitations.value = [];
  }
});

export default function useMembers () {
  return {
    members: readonly(members),
    invitations: readonly(invitations),
    setOrganizationMembers,
    setOrganizationInvitations,
    getMembersList,
    updateMember,
    deleteMember,
    createInvitation,
    createApplicationInvitation,
    listUnacceptedInvitations,
  };
}
