<script setup>
import { nextTick, ref, watch } from 'vue';

import useArtifacts from '@/composables_NEW/useArtifacts';

import Icon from '@/components_NEW/common/Icon.vue';

import { ArtifactTypes } from '@/constants/artifacts';

const props = defineProps({
  altImage: String,
  fileId: String,
});

const { getMetaFile } = useArtifacts();

const imageUrl = ref('');
const isLoaded = ref(false);

watch(() => props.fileId, async (newVal) => {
  if (newVal) {
    isLoaded.value = false;
    const metaFileUrl = await getMetaFile(ArtifactTypes.File, newVal);
    imageUrl.value = metaFileUrl;
  }
}, { immediate: true });
</script>

<template>
  <div class="relative flex items-center justify-center overflow-hidden">
    <img v-if="altImage && !fileId"
      :src="altImage"
      alt="gluecharm"
      class="absolute top-0 left-0 w-full h-full object-cover rounded-sm"
    />
    <img
      alt="gluecharm"
      :src="imageUrl"
      class="absolute top-0 left-0 w-full h-full z-10 object-cover rounded-sm transition-opacity"
      @load="(ev) => {
        nextTick(() => {
          isLoaded = true;
        });
      }"
      :class="[
        isLoaded ? 'opacity-100' : 'opacity-0',
      ]"
    />
    <div class="w-full h-full absolute top-0 left-0 z-20 flex items-center justify-center">
      <Icon
        v-if="fileId && !isLoaded"
        icon="loading"
        class="w-full h-full max-h-12 max-w-12"
      />
    </div>
  </div>
</template>
