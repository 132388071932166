<script setup>
import GlueButton from '@/components_NEW/formFields/GlueButton.vue';
import Icon from '@/components_NEW/common/Icon.vue';

import { ButtonSizes, FormStyles, FormTextAligns } from '@/constants/forms';
import { LinkTypes, TableColumnTypes } from '@/constants/tables';
import { isEmptyData } from '@/utils/utils';
import { computed } from 'vue';

const props = defineProps({
  icon: String,
  centered: Boolean,
  content: Array,
  type: String,
});

const contentHasOnlyIconLinks = computed(() => {
  return !!props.content?.find(link => link.label || link.description);
});
</script>

<template>
  <div
    class="flex gap-0 -mt-1"
    :class="[
      contentHasOnlyIconLinks && 'flex-col',
      centered && `text-center ${contentHasOnlyIconLinks ? 'items-center' : 'justify-center'}`,
      !centered && `text-left ${contentHasOnlyIconLinks ? 'items-start' : 'justify-start'}`,
    ]"
  >
    <div
      v-for="(linkContent, linkIndex) in content"
      :key="linkIndex"
      class="flex items-start"
      :class="content.length > 1 && contentHasOnlyIconLinks ? 'odd:bg-white bg-gray-50' : 'bg-transparent'"
    >
      <Icon
        class="flex-shrink-0 w-5 h-5 mr-1 mt-0.5"
        v-if="icon && !isEmptyData(content)"
        :icon="icon"
      />
      <div class="flex flex-col -ml-1 items-start">
        <GlueButton
          class="text-nowrap"
          :class="[
            linkContent.class ? linkContent.class : '',
            ]"
          :text-align="FormTextAligns.Left"
          :color="linkContent.color"
          :icon="linkContent.icon"
          :label="linkContent.label"
          :tooltip="linkContent.tooltip"
          :fetching-action="linkContent.fetchingAction"
          :waiting-for="linkContent.waitingFor"
          :confirm-message="linkContent.confirmMessage"
          :style="FormStyles.Naked"
          :size="linkContent.size || (type === TableColumnTypes.Actions ? ButtonSizes.Default : ButtonSizes.Big)"
          :disabled="linkContent.type === LinkTypes.Disabled || linkContent.disabled"
          :to="linkContent.route"
          :href="linkContent.href"
          @click-button="() => {
            if (!!linkContent.action) {
              linkContent.action();
            } 
          }"
        />
        <div class="text-xs text-gray-800 select-text max-h-12 text-ellipsis line-clamp-3 overflow-hidden ml-1" v-if="linkContent.description" v-html="linkContent.description"/>
      </div>
    </div>
  </div>
</template>