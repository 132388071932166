<script setup>
import { computed, nextTick, ref, watch } from 'vue';

import Tooltip from './Tooltip.vue';
import GlueCheck from './GlueCheck.vue';
import GlueLabel from './GlueLabel.vue';

import { CheckTypes, MultipleSelectionDirection } from '@/constants/forms';

const props = defineProps({
  modelValue: {
    type: Array,
    default: () => [],
  },
  label: String,
  tooltip: String,
  options: Object,
  errorMessage: String,
  required: Boolean,
  disabled: Boolean,
  type: String,
  direction: {
    type: String,
    default: MultipleSelectionDirection.Vertical,
  },
  allowMultiple: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(['update:modelValue', 'blur']);

const tooltipRef = ref();
const containerDom = ref();

const optionModels = ref({});

const checkType = computed(() => props.type || props.allowMultiple ? CheckTypes.Checkbox : CheckTypes.RadioButton);

function hideError () {
  tooltipRef.value?.hide();
}

function showError () {
  tooltipRef.value.show(props.errorMessage, {
    top: props.label ? 20 : 0,
    left: containerDom.value?.offsetWidth / 2,
  });
}

function validate () {
  const isValid = !props.required || Object.values(optionModels.value).length;

  if (!isValid) {
    showError();
  }

  return isValid;
}

function onChangeCheckbox(optionId) {
  if (!props.allowMultiple) {
    optionModels.value = {[optionId]: optionModels.value[optionId]};
  }

  const value = Object.keys(optionModels.value).filter(option => !!optionModels.value[option]);
  emit('update:modelValue', value);
  hideError();

  nextTick(() => {
    emit('blur');
  });
}

function showTooltip() {
  if (props.tooltip) {
    tooltipRef.value.show(props.tooltip, {top: 0, left: containerDom.value.offsetWidth / 2});
  }
}

function hideTooltip() {
  if (props.tooltip) {
    tooltipRef.value.hide();
  }
}

defineExpose({
  validate,
});

watch(() => props.modelValue, () => {
  if (props.modelValue) {
    nextTick(() => {
      Object.values(props.options || {}).forEach((option) => {
        if (!props.modelValue.includes(option)) {
          optionModels.value[option] = false;
        } else {
          optionModels.value[option] = true;
        }
      });
    });
  }
}, {immediate: true});
</script>

<template>
  <div
    class="relative flex flex-col"
    ref="rootRef"
  >
    <Tooltip
      ref="tooltipRef"
      :auto-hide="3000"
      :close-button="false"
    />
    <GlueLabel v-if="label" :label="label" />
    <div
      ref="containerDom"
      class="flex flex-wrap box-border relative transition-colors font-light text-sm gap-x-8 gap-y-2"
      :class="[
        direction === MultipleSelectionDirection.Horizontal ? 'flex-row' : 'flex-col',
        label ? 'mt-2' : '',
        tooltipRef?.showing ? 'border-red-900' : disabled ? 'border-gray-300' : 'border-gray-400',
      ]"
      @mouseenter="showTooltip"
      @mouseleave="hideTooltip"
    >
      <GlueCheck
        v-for="(value, key) in options"
        :disabled="disabled"
        :key="key"
        :type="checkType"
        :label="key"
        :checked="!!optionModels[value]"
        :label-bold="!!optionModels[value]"
        @check="() => {
          optionModels[value] = key;
          onChangeCheckbox(value);
        }"
        @uncheck="() => {
          optionModels[value] = false;
          onChangeCheckbox(value);
        }"
      />
    </div>
  </div>
</template>
