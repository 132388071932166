<script setup>
import { ref, computed } from 'vue';

import { v4 as uuidv4 } from 'uuid';

import useLanguages from '@/composables_NEW/useLanguages';
import useApplications from '@/composables_NEW/useApplications';
import useMembers from '@/composables_NEW/useMembers';
import useUsers from '@/composables_NEW/useUsers';
import useFetching from '@/composables_NEW/useFetching';
import useWorkspaces from '@/composables_NEW/useWorkspaces';

import Popup from './Popup.vue';
import PopupHeader from './PopupHeader.vue';
import GlueInput from '@/components_NEW/formFields/GlueInput.vue';
import GlueButton from '@/components_NEW/formFields/GlueButton.vue';

import { InvitationTypes, PopupSizes } from '@/constants/common';
import { FormBorderStyles, InputTypes } from '@/constants/forms';
import { PermissionTypes } from '@/constants/application';

import { parseJwt } from '@/utils/utils';

const fetchingAction = 'sendProjectInvitation';

const { t } = useLanguages();

const { fetchingErrors, addFetchingAction, setFetchingActionDone, addFetchingError } = useFetching();
const { activeApplication } = useApplications();
const { invitations, createApplicationInvitation, listUnacceptedInvitations } = useMembers();
const { listByPartialEmail, listUsersByApplication } = useUsers();
const { activeWorkspace } = useWorkspaces();

const popupRef = ref();
const invitationEmailRef = ref();
const invitationEmailModel = ref();

function onClickSendInvitation() {
  const isValid = invitationEmailRef.value.validate();

  if (isValid) {
    sendInvitation();
  }
}

const invitationsData = computed(() => (invitations.value || [])
  .filter(invitationData => {
    const parsedHash = parseJwt(invitationData.hash);
    return parsedHash?.metadata.application_id === activeApplication.value.id;
  })
  .reduce((confsArr, invitationData) => {
    confsArr.push({
      ...invitationData,
      status: t(invitationData.status),
      date: new Date(invitationData.created_at*1000).toLocaleDateString(),
    });
    return confsArr;
  }, []));


async function sendInvitation() {
  addFetchingAction(fetchingAction);

  let type = InvitationTypes.ProjectRegisterInvitation;
  let user_id = uuidv4();

  const usersList = await listByPartialEmail(invitationEmailModel.value);
  const existingUser = usersList?.find(user => user.email === invitationEmailModel.value);
  
  if (existingUser) {
    type = InvitationTypes.ProjectInvitation;
    user_id = existingUser.id;
  }

  const invitationSettings = {
    email: invitationEmailModel.value,
    invitation_id: uuidv4(),
    organization_id: activeApplication.value.ownership_workspace_id,
    application_id: activeApplication.value.id,
    application_role: PermissionTypes.Full,
    type,
    user_id,
  };

  const created = await createApplicationInvitation(invitationSettings);

  if (created) {
    invitationEmailRef.value.clear();
    await listUnacceptedInvitations(activeApplication.value.ownership_workspace_id);
    setFetchingActionDone(fetchingAction);
  } else {
    addFetchingError(fetchingAction, fetchingErrors.value['createApplicationInvitation']);
  }
}

function open() {
  listUsersByApplication(activeApplication.value.id);
  popupRef.value.open();
}

function close() {
  popupRef.value.close();
}

defineExpose({
  open,
  close,
});
</script>

<template>
  <Popup
    ref="popupRef"
    :size="PopupSizes.Medium"
  >
    <PopupHeader
      icon="users-three"
      :title="t('Share project')"
      :description="[t('Send an invite to join your project')]" 
    />
    <div class="flex flex-col w-full max-w-60 mx-auto mt-8 gap-4">
      <GlueInput
        ref="invitationEmailRef"
        class="h-10"
        id="share-email"
        :type="InputTypes.Email"
        :border-style="FormBorderStyles.Straight"
        :required="true"
        :placeholder="t('Enter email address')"
        :error-message="t('Please, enter a valid email address')"
        v-model="invitationEmailModel"
      />
      <!--
        <GlueDropdown
          ref="typeDropdownRef"
          class="h-10"
          :required="true"
          :options="Object.keys(PermissionTypeLiterals).reduce((acc, permission) => {
            acc[PermissionTypeLiterals[permission]] = permission;
            return acc;
          }, {})"
          v-model="permissionModel"
        />
      -->
      <GlueButton
        :label="t('Send Invite')"
        :fetching-action="fetchingAction"
        :tooltip="t('Send Invitation')"
        @click-button="onClickSendInvitation"
      />
    </div>
    <div v-if="invitationsData" class="text-lg font-medium py-4 mt-4 border-t">
      {{ t('Invited to this project') }}
    </div>
    <div
      class="flex gap-1 text-sm"
      v-for="{invitation_id, email, status, application_role} in invitationsData"
      :key="invitation_id"
    >
      <span class="text-indigo-700">{{ email }}</span>
      <span class="flex-grow">({{ application_role || 'read-only' }})</span>
      <span class="w-16 text-right">{{ status }}</span>
    </div>
  </Popup>
</template>
