import { computed, nextTick, watch } from 'vue';
import { createWebHistory, createRouter } from 'vue-router';

import useGlueCharmServices from '@/composables_NEW/useGlueCharmServices';
import useAnalytics from '@/composables_NEW/useAnalytics';

import { Language, RouteNames } from './constants/common';

const DEFAULT_TITLE = 'Gluecharm';

const { trackNavigation } = useAnalytics();
const { libraryInitialized, isUserAuthenticated } = useGlueCharmServices();

const routes = [
  {
    path: '/',
    redirect: RouteNames.Login,
  },
  {
    path: '/:lang?/login',
    name: RouteNames.Login,
    meta: {
      public: true,
    },
    component: async () => await import('./views_NEW/Login.vue'),
  },
  {
    path: '/:lang?/upgrade',
    name: RouteNames.Upgrade,
    component: async () => await import('./views_NEW/Upgrade.vue'),
  },
  {
    path: '/:lang?/reset-password',
    name: RouteNames.ResetPassword,
    meta: {
      public: true,
    },
    component: async () => await import('./views_NEW/ResetPassword.vue'),
  },
  {
    path: '/:lang?/register/:hash?',
    name: RouteNames.Register,
    meta: {
      public: true,
    },
    component: async () => await import('./views_NEW/Register.vue'),
  },
  {
    path: '/:lang?/no-workspace',
    name: RouteNames.NoOrganization,
    component: async () => await import('./views_NEW/NoOrgError.vue'),
  },
  {
    path: '/:lang?/:org?/:upgrade?',
    name: RouteNames.Workspace,
    component: async () => await import('./views_NEW/Workspace.vue'),
  },
  {
    path: '/:lang?/manage-workspace/:org?/:tab?',
    name: RouteNames.ManageWorkspace,
    component: async () => await import('./views_NEW/ManageWorkspace.vue'),
  },
  {
    path: '/:lang?/:org/new-application',
    name: RouteNames.NewApplication,
    component: async () => await import('./views_NEW/NewApplication.vue'),
  },
  {
    path: '/:lang?/:org/apps/:appId/:tab?',
    name: RouteNames.Application,
    component: async () => await import('./views_NEW/Application.vue'),
  },
  {
    path: '/:lang?/:org/apps/:appId/users',
    name: RouteNames.Users,
    component: async () => await import('./views_NEW/Actors.vue'),
  },
  {
    path: '/:lang?/:org/apps/:appId/users/:userId/:tab?',
    name: RouteNames.User,
    component: async () => await import('./views_NEW/Actor.vue'),
  },
  {
    path: '/:lang?/:org/apps/:appId/users/:userId/user-journeys/:journeyId',
    name: RouteNames.CustomerJourney,
    component: async () => await import('./views_NEW/CustomerJourney.vue'),
  },
  {
    path: '/:lang?/:org/apps/:appId/services',
    name: RouteNames.Services,
    component: async () => await import('./views_NEW/Services.vue'),
  },
  {
    path: '/:lang?/:org/apps/:appId/frontend_service/:serviceId/methods?/:methodId?',
    name: RouteNames.FrontendService,
    component: async () => await import('./views_NEW/FrontendService.vue'),
  },
  {
    path: '/:lang?/:org/apps/:appId/features/:tab?',
    name: RouteNames.Features,
    component: async () => await import('./views_NEW/Features.vue'),
  },
  {
    path: '/:lang?/:org/apps/:appId/feature/:artifactId/:tab?',
    name: RouteNames.Feature,
    component: async () => await import('./views_NEW/Feature.vue'),
  },
  {
    path: '/:lang?/:org/apps/:appId/usecases/:artifactId/:tab?',
    name: RouteNames.Usecase,
    component: async () => await import('./views_NEW/Usecase.vue'),
  },
  {
    path: '/:lang?/:org/apps/:appId/user-stories',
    name: RouteNames.UserStories,
    component: async () => await import('./views_NEW/UserStories.vue'),
  },
  {
    path: '/:lang?/:org/apps/:appId/user-stories/:artifactId',
    name: RouteNames.UserStory,
    component: async () => await import('./views_NEW/UserStory.vue'),
  },
  {
    path: '/:lang?/:org/apps/:appId/events',
    name: RouteNames.Events,
    component: async () => await import('./views_NEW/Events.vue'),
  },
  {
    path: '/:lang?/:org/apps/:appId/events/:eventId',
    name: RouteNames.Event,
    component: async () => await import('./views_NEW/Event.vue'),
  },
  {
    path: '/:lang?/:org/apps/:appId/human_interactions/:humanInteractionId',
    name: RouteNames.HumanInteraction,
    component: async () => await import('./views_NEW/HumanInteraction.vue'),
  },
  {
    path: '/:lang?/:org/apps/:appId/data-types',
    name: RouteNames.DataTypes,
    component: async () => await import('./views_NEW/DataTypes.vue'),
  },
  {
    path: '/:lang?/:org/apps/:appId/environments',
    name: RouteNames.Environments,
    component: async () => await import('./views_NEW/Environments.vue'),
  },
  {
    path: '/:lang?/:org/apps/:appId/code-versions',
    name: RouteNames.Versions,
    component: async () => await import('./views_NEW/CodeVersions.vue'),
  },
  {
    path: '/:lang?/:org/apps/:appId/code-generation',
    name: RouteNames.Generation,
    component: async () => await import('./views_NEW/Generator.vue'),
  },
  {
    path: '/:lang?/:org/apps/:appId/deployments',
    name: RouteNames.Deployments,
    component: async () => await import('./views_NEW/CodeDeployment.vue'),
  },
  {
    path: '/:lang?/:org/apps/:appId/backlog/:backlogId?',
    name: RouteNames.Backlog,
    component: async () => await import('./views_NEW/Backlog.vue'),
  },
  {
    path: '/:lang?/:org/apps/:appId/views',
    name: RouteNames.Views,
    component: async () => await import('./views_NEW/Views.vue'),
  },
  {
    path: '/:lang?/:org/apps/:appId/views/:viewId',
    name: RouteNames.View,
    component: async () => await import('./views_NEW/View.vue'),
  },
  {
    path: '/:lang?/:org/apps/:appId/human-interactions',
    name: RouteNames.HumanInteractionList,
    component: async () => await import('./views_NEW/HumanInteractionList.vue'),
  },
  {
    path: '/:lang?/:org/apps/:appId/human-interactions/:humanInteractionId',
    name: RouteNames.HumanInteraction,
    component: async () => await import('./views_NEW/HumanInteraction.vue'),
  },
  {
    path: '/:lang?/:org/apps/:appId/contents/:artifactType/list',
    name: RouteNames.ArtifactList,
    component: async () => await import('./views_NEW/MagicArtifacts.vue'),
  },
  {
    path: '/:lang?/:org/apps/:appId/contents/:artifactType/:artifactId/:tab?',
    name: RouteNames.Artifact,
    component: async () => await import('./views_NEW/MagicArtifact.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: RouteNames.Login,
  },
];

export const router = createRouter({
  history: createWebHistory(),
  routes,
});

export const activeApplicationId = computed(() => router.currentRoute.value.params.appId);
export const activeWorkspaceSlug = computed(() => router.currentRoute.value.params.org);

router.beforeEach(async (to) => {
  if (!libraryInitialized.value) {
    await new Promise((resolve) => {
      const stopWatch = watch(libraryInitialized, () => {
        stopWatch();
        resolve();
      });
    });
  }

  if (!isUserAuthenticated.value && !to.meta.public) {
    return {
      name: RouteNames.Login,
    };
  }
  
  if (isUserAuthenticated.value && to.meta.public) {
    return false;
  }
  
  if (!to.params.lang) {
    return {
      ...to,
      params: {
        ...to.params,
        lang: Language.EN,
      },
    };
  }
});

router.afterEach((to) => {
  nextTick(() => {
    document.title = `${DEFAULT_TITLE}-${to.name}`;
    trackNavigation({
      pageName: to.name,
    });
  });
});
