<script setup>
import { computed } from 'vue';
import Icon from '@/components_NEW/common/Icon.vue';

import { isEmptyData } from '@/utils/utils';

const props = defineProps({
  icon: String,
  centered: Boolean,
  content: [Object, String],
  type: String,
});

const parsedContent = computed(() => {
  if (typeof props.content === 'object') {
    return props.content;
  }
  return { text: props.content };
});
</script>

<template>
  <div
    class="flex flex-col"
    :class="[
      parsedContent?.class,
      centered ? 'items-center' : 'items-start',
    ]"
  >
    <div
      v-if="parsedContent?.label"
      class="font-semibold"
      v-html="parsedContent.label"
    />
    <div class="flex items-center">
      <Icon
        v-if="icon && !isEmptyData(parsedContent)"
        class="flex-shrink-0 w-5 h-5 mr-1"
        :icon="icon"
      />
      <Icon v-if="content?.icon" :icon="content.icon" class="flex-shrink-0 w-8 h-8 p-0.5"/>
      <span
        v-if="parsedContent?.text"
        class="select-text"
        :class="centered ? 'text-center flex justify-center object-center' : 'text-left'"
        v-html="parsedContent?.text"
      />
    </div>
  </div>
</template>