import { computed, readonly, ref } from 'vue';

import useFetching from '@/composables_NEW/useFetching';

import { DataAssetTypes, DiffTypes } from '@/constants/dataAssets';
import { deepEqualData } from '@/utils/utils';
import { FlowNodeTypes } from '@/constants/flows';

const {
  fetchingActions, fetchingErrors, addFetchingAction,
  setFetchingActionDone, addFetchingError, clearFetchingErrors,
} = useFetching();

const dataAssets = ref({});

const DiffParamNameByAssetType = {
  [DataAssetTypes.Events]: {
    [FlowNodeTypes.HumanInteraction]: {
      name: 'what',
      reason: 'how',
      notes: 'why',
    },
    [FlowNodeTypes.RequestMessage]: {
      notes: 'context',
    },
    [FlowNodeTypes.ApplicationMessage]: {
      notes: 'context',
    },
  },
};

function getDiffType(originalValue, editedValue) {
  if (!originalValue.created_at) {
    return DiffTypes.Created;
  } else if (!editedValue || editedValue.__deleted__) {
    return DiffTypes.Deleted;
  } else {
    return DiffTypes.Updated;
  }
}

function getDiffContent(type, originalValue, editedValue) {
  return Object.keys(editedValue)
    .filter(propId => !deepEqualData(originalValue[propId], editedValue[propId]))
    .map(propId => DiffParamNameByAssetType[type]?.[editedValue.type]?.[propId] || propId);
}

const changesHistory = computed(() => {
  const editedItemsHistory = Object.values(dataAssets.value).reduce((editedArr, item) => [
    ...editedArr,
    ...Object.keys(item.editedItems || {}).filter(editedItemId => !!item.itemsRef[editedItemId]).map(editedItemId => {
      const originalValue = {...item.editedItems[editedItemId]};
      const editedValue = item.itemsRef[editedItemId];
      const diffType = getDiffType(originalValue, editedValue);
      const diffContent = getDiffContent(item.type, originalValue,editedValue);
      const isFetching = fetchingActions.value.has(`saveLocalItemChanges-${editedItemId}`);

      return {
        type: item.type,
        id: editedItemId,
        diffType,
        diffContent,
        originalValue,
        editedValue,
        isFetching,
      };
    }),
  ], []).sort((itemA, itemB) => itemB.originalValue.editedAt - itemA.originalValue.editedAt);

  return editedItemsHistory;
});
  
const hasChanges = computed(() => !!changesHistory.value?.length);

async function saveAllChanges() {
  const saveOrder = Object.values(DataAssetTypes);
  
  clearFetchingErrors();
  addFetchingAction('saveAllChanges');
  
  let success = true;

  for (const element of saveOrder) {
    const itemName = element;
    
    const itemSuccess = await Promise.all(Object.keys(dataAssets.value[itemName]?.editedItems || {}).map(itemId => {
      return dataAssets.value[itemName].saveLocalItemChanges(itemId);
    }));

    success = !itemSuccess.includes(false);

    if (!success) {
      break;
    }
  }

  if (!success){
    addFetchingError('saveAllChanges', Object.values(fetchingErrors.value)[0]);
    return false;
  }

  setFetchingActionDone('saveAllChanges');

  return true;
}

function cancelAllChanges() {
  if (!fetchingActions.value.has('saveAllChanges')) {
    Object.values(dataAssets.value).forEach(dataAsset => {
      Object.keys(dataAsset.editedItems).forEach(editedItemId => {
        dataAsset.cancelLocalItemChanges(editedItemId);
      });
    });
  }
}

function addDataAsset(type, valueObj) {
  dataAssets.value[type] = {
    type,
    ...valueObj,
  };
}

export default function useDataAssets() {
  return {
    dataAssets: readonly(dataAssets),
    hasChanges: readonly(hasChanges),
    changesHistory,
    addDataAsset,
    saveAllChanges,
    cancelAllChanges,
  };
}
