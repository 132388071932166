<script setup>
import { computed } from 'vue';

import useLanguages from '@/composables_NEW/useLanguages';
import useApplications from '@/composables_NEW/useApplications';
import useArtifacts from '@/composables_NEW/useArtifacts';
import useFetching from '@/composables_NEW/useFetching';

import Icon from '@/components_NEW/common/Icon.vue';

import { ArtifactTypes } from '@/constants/artifacts';

const exportTypes = {
  pdf: 'brief_pdf',
  //html: 'brief_html',
};

const exportTypeExtensions = {
  [exportTypes.pdf]: 'pdf',
  [exportTypes.html]: 'html',
};

const { t } = useLanguages();
const { activeApplication } = useApplications();
const { getDocumentationBrief, getMetaFile } = useArtifacts();
const { fetchingActions } = useFetching();

const isFetching = computed(() => {
  return fetchingActions.value.has(`get-brief-${activeApplication.value.id}`);
});

async function exportDocumentation() {
  const fileName = `${activeApplication.value.name}.pdf`;
  const brief = await getDocumentationBrief(activeApplication.value.id);
  const fileUrl = await getMetaFile(ArtifactTypes.File, brief.file_id);
  
  const link = document.createElement('a');
  link.href = fileUrl;
  link.download = fileName;
  link.dispatchEvent(new MouseEvent('click'));
}
</script>

<template>
  <div class="w-full p-8 pt-24">
    <div class="text-sm pb-4">
      {{ t('Export the documentation generated.') }}
    </div>
    <div class="flex flex-col gap-2 mt-4 border-t border-slate-200">
      <div class="flex gap-2 py-4 justify-between items-center border-b">
        <div class="font-bold">{{ t('All Documentation') }}</div>
        <div class="flex gap-4">
          <div
            class="relative cursor-pointer bg-slate-50 text-cyan-600 border-slate-200 border hover:bg-cyan-600 hover:text-white transition-all p-2 rounded-full"
            @click="exportDocumentation"
          >
            <Icon
              class="w-6 h-6"
              icon="file-pdf"
            />
            <Icon icon="loading" class="absolute top-1 left-1 w-8 h-8 z-20" v-if="isFetching" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
