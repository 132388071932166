<script setup>
import { GCMailTypes } from '@/constants/common';
import { FormThemes, FormStyles, FormColors } from '@/constants/forms';
import { computed } from 'vue';

const props = defineProps({
  type: {
    type: String,
    default: GCMailTypes.Help,
  },
  label: String,
  subject: String,
  style: {
    type: String,
    default: FormStyles.Naked,
  },
  color: {
    type: String,
    default: FormColors.Indigo,
  },
});

const GCmails = {
  [GCMailTypes.Help]: 'help@gluecharm.com',
  [GCMailTypes.Upgrade]: 'gisella.fama@gluecharm.com',
  [GCMailTypes.Design]: 'gisella.fama@gluecharm.com',
  [GCMailTypes.Founders]: 'founders@gluecharm.com',
  [GCMailTypes.Calendly]: 'https://calendly.com/gluecharm-founders',
  [GCMailTypes.Gift]: 'help+invitations@gluecharm.com',
};

const email = computed(() => GCmails[props.type]);

</script>

<template>
  <a
    class="text-indigo-700 font-medium hover:bg-indigo-100 border box-border"
    :class="[
      FormThemes[color][style]
    ]"
    :href="`${type === GCMailTypes.Calendly ? '' : 'mailto:'}${email}${subject ? `?subject=${subject}` : ''}`"
    target="gc-mail"
  >{{ label || email }} {{ }}</a>
</template>
