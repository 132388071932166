<script setup>
import { computed, ref } from 'vue';

import useLanguages from '@/composables_NEW/useLanguages';
import useWorkspaces from '@/composables_NEW/useWorkspaces';
import useGlueCharmServices from '@/composables_NEW/useGlueCharmServices';
import useUsers from '@/composables_NEW/useUsers';

import Popup from '@/components_NEW/common/Popup.vue';
import GcEmailLink from '@/components_NEW/common/GcEmailLink.vue';
import PopupHeader from '@/components_NEW/common/PopupHeader.vue';
import Icon from '@/components_NEW/common/Icon.vue';
import GlueButton from '@/components_NEW/formFields/GlueButton.vue';

import { viteEnv, GCMailTypes, PopupSizes } from '@/constants/common';
import { FormStyles } from '@/constants/forms';
import { SubscriptionTypeLimits, SubscriptionTypeLiterals, SubscriptionTypeUpgradeContents, SubscriptionTypeUpgrades } from '@/constants/subscription';
import { isMobile, isNumber } from '@/utils/utils';

defineProps({
  applicationId: String,
  sharePopup: Object,
});

const { t } = useLanguages();
const { activeWorkspace } = useWorkspaces();
const { fetchGlueCharmMethod } = useGlueCharmServices();
const { activeUser } = useUsers();

const popupRef = ref();
const upgradeType = ref();
const paymentUrl = ref();

const upgradeSubscription = computed(() => SubscriptionTypeUpgrades[activeWorkspace.value?.subscription_type]);
const upgradeContents = computed(() => SubscriptionTypeUpgradeContents[upgradeSubscription.value] || {price: 0, features: []});
const priceString = computed(() => isNumber(upgradeContents.value.price) ? `$ ${upgradeContents.value.price}/${t('month/seat')}` : t(upgradeContents.value.price));
const allowGift = computed(() => SubscriptionTypeLimits[activeWorkspace.value?.subscription_type]?.allowGift);
const upgradeImage = computed(() => upgradeType.value && `/upgrade/${upgradeType.value}${isMobile() ? '-mobile' : ''}.png`);

async function getSubscriptionPaymentLink() {
  const generatedLink = await fetchGlueCharmMethod('create-payment-link', 'getSubscriptionPaymentLink', {
    user_id: activeUser.value.id,
    organization_id: activeWorkspace.value.id,
    plan_name: upgradeSubscription.value,
    test: viteEnv === 'development' && !window.forceRealPayment,
  }, 'gluex');

  paymentUrl.value = generatedLink.data?.payment_link;
}

function open(upgradeTypeRef) {
  upgradeType.value = upgradeTypeRef;
  popupRef.value.open();

  getSubscriptionPaymentLink();
}

function close() {
  popupRef.value.close();
}

defineExpose({
  open,
  close,
});
</script>

<template>
  <Popup
    ref="popupRef"
    :size="PopupSizes.Auto"
    :avoid-outside-close="true"
    :no-padding="true"
  >
    <div class="flex flex-col md:flex-row rounded-2xl">
      <img v-if="upgradeImage"
        class="contain-size"
        :src="upgradeImage"
        width="400"
      >
      <div class="flex flex-col p-0 max-w-full pt-6 md:pt-10 overflow-y-scroll"
        :style="{
          width: PopupSizes.Small,
        }"
      >
        <PopupHeader  
          icon="certificate"
          :title="t('Upgrade')"
          :description="[`${t('Upgrade to')} <b>${t(SubscriptionTypeLiterals[upgradeSubscription])}</b> ${t('Account')}`]"
        />
        <div class="flex flex-col pt-4 pb-8 text-slate-700 items-center px-12">
          <div class="text-2xl font-medium text-center">{{ priceString }}</div>
          <div class="my-6 text-sm flex flex-col gap-2">
            <div
              class="flex gap-2 items-start font-medium text-sm justify-start"
              v-for="feature in upgradeContents.features"
              :key="feature"
            >
              <Icon class="flex-shrink-0 w-6 h-6 text-indigo-700" icon="check" />
              <span class="mt-0.5">{{ t(feature) }}</span>
            </div>
          </div>
          <GlueButton
            :label="t('Upgrade now')"
            class="w-48"
            target="_self"
            fetching-label="Wait..."
            fetching-action="getSubscriptionPaymentLink"
            :href="paymentUrl"
          />
        </div>
        <div v-if="allowGift" class="flex gap-5 items-start text-sm justify-center pt-6 pb-4 border-t">
          <Icon class="flex-shrink-0 w-10 h-10 bg-indigo-100 p-2 rounded-full text-indigo-700" icon="gift" />
          <div class="flex flex-col mr-14">
            <span>{{ t('Get') }} <b>{{ t('1 free new project') }}</b><br/> {{ t('by inviting 3 friends to sign up') }}.</span>
            <GcEmailLink
              :label="t('Contact us for your gift')"
              class="w-48 py-1 mt-4 text-center"
              :type="GCMailTypes.Gift"
              :subject="t('Gift by inviting friends')"
              :style="FormStyles.Outline"
            />
          </div>
        </div>
      </div>
    </div>
  </Popup>
</template>
