export const SubscriptionTypes = {
  FREE: 'free',
  FREE_1: 'Free 1',
  FREE_2: 'Free 2',
  FREE_3: 'Free 3',
  FREE_4: 'Free 4',
  YEARLY: 'yearly',
  PREMIUM: 'Premium',
  PREMIUM_PLUS: 'Premium Plus',
  ENTERPRISE: 'Enterprise',
};

export const SubscriptionTypeLiterals = {
  [SubscriptionTypes.FREE]: 'Free',
  [SubscriptionTypes.FREE_1]: 'Free',
  [SubscriptionTypes.FREE_2]: 'Free',
  [SubscriptionTypes.FREE_3]: 'Free',
  [SubscriptionTypes.FREE_4]: 'Free',
  [SubscriptionTypes.YEARLY]: 'Enterprise',
  [SubscriptionTypes.PREMIUM]: 'Premium',
  [SubscriptionTypes.PREMIUM_PLUS]: 'Premium Plus',
  [SubscriptionTypes.ENTERPRISE]: 'Enterprise',
};

export const SubscriptionTypeIcons = {
  [SubscriptionTypes.FREE]: 'baby',
  [SubscriptionTypes.FREE_1]: 'baby',
  [SubscriptionTypes.FREE_2]: 'baby',
  [SubscriptionTypes.FREE_3]: 'baby',
  [SubscriptionTypes.FREE_4]: 'baby',
  [SubscriptionTypes.YEARLY]: 'smiley-wink',
  [SubscriptionTypes.PREMIUM]: 'smiley-wink',
  [SubscriptionTypes.PREMIUM_PLUS]: 'smiley-wink',
  [SubscriptionTypes.ENTERPRISE]: 'smiley-wink',
};

export const SubscriptionTypeLimits = {
  [SubscriptionTypes.FREE]: {
    maxApplications: 1,
    allowGift: true,
  },
  [SubscriptionTypes.FREE_1]: {
    maxApplications: 1,
    allowGift: true,
  },
  [SubscriptionTypes.FREE_2]: {
    maxApplications: 2,
    allowGift: true,
  },
  [SubscriptionTypes.FREE_3]: {
    maxApplications: 3,
    allowGift: true,
  },
  [SubscriptionTypes.FREE_4]: {
    maxApplications: 4,
  },
  [SubscriptionTypes.PREMIUM]: {
    maxApplications: 5,
    allowExport: true,
    allowUsecasesReport: true,
    allowExportToJira: true,
  },
  [SubscriptionTypes.PREMIUM_PLUS]: {
    maxApplications: 1000,
    allowExport: true,
    allowUsecasesReport: true,
    allowExportToJira: true,
  },
  [SubscriptionTypes.ENTERPRISE]: {
    maxApplications: 10000,
    allowExport: true,
    allowUsecasesReport: true,
    allowExportToJira: true,
  },
  [SubscriptionTypes.YEARLY]: {
    maxApplications: 10000,
    allowExport: true,
    allowUsecasesReport: true,
    allowExportToJira: true,
  },
};

export const SubscriptionPaymentUrls = {
  [SubscriptionTypes.PREMIUM]: 'https://buy.stripe.com/aEUeXYbPjbZHfW8fZ1',
  [SubscriptionTypes.PREMIUM_PLUS]: 'https://buy.stripe.com/bIYcPQ5qVe7P9xK6os',
};

export const SubscriptionTypeUpgrades = {
  [SubscriptionTypes.FREE]: SubscriptionTypes.PREMIUM,
  [SubscriptionTypes.FREE_1]: SubscriptionTypes.PREMIUM,
  [SubscriptionTypes.FREE_2]: SubscriptionTypes.PREMIUM,
  [SubscriptionTypes.FREE_3]: SubscriptionTypes.PREMIUM,
  [SubscriptionTypes.FREE_4]: SubscriptionTypes.PREMIUM,
  [SubscriptionTypes.PREMIUM]: SubscriptionTypes.PREMIUM_PLUS,
  [SubscriptionTypes.PREMIUM_PLUS]: SubscriptionTypes.ENTERPRISE,
};

export const SubscriptionTypeUpgradeContents = {
  [SubscriptionTypes.PREMIUM]: {
    price: 20,
    features: [
      '5 projects',
      'Unlimited Use Cases',
      'Unlimited User Stories',
      'Advanced AI Mapping',
      'Export to CSV, Excel',
      'JIRA Integration',
      'Email Support',
    ],
  },
  [SubscriptionTypes.PREMIUM_PLUS]: {
    price: 40,
    features: [
      'Unlimited projects',
      'Unlimited Use Cases',
      'Unlimited User Stories',
      'Advanced AI Mapping',
      'Export to CSV, Excel',
      'JIRA Integration',
      'Email Support',
      'Phone Support',
    ],
  },
  [SubscriptionTypes.ENTERPRISE]: {
    price: 'Custom price',
    features: [
      'All in Premium Plus +',
      'Hosted Service',
      'Custom Features',
      'Advanced Support',
    ],
  },
};