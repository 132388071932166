<script setup>
import { computed, ref } from 'vue';

import useApplications from '@/composables_NEW/useApplications';

import GlueButton from '@/components_NEW/formFields/GlueButton.vue';
import AgentSidebarSuggestions from '@/components_NEW/agent/AgentSidebarSuggestions.vue';
import AgentSidebarNotes from '@/components_NEW/agent/AgentSidebarNotes.vue';
import AgentSidebarExport from '@/components_NEW/agent/AgentSidebarExport.vue';

import { ButtonSizes, FormColors } from '@/constants/forms';

const items = [
  {
    image: '/agent.png',
    label: 'Assistant',
    component: AgentSidebarSuggestions,
  },
  {
    icon: 'notepad',
    label: 'Notes',
    component: AgentSidebarNotes,
  },
  {
    icon: 'arrow-up-right',
    label: 'Export',
    component: AgentSidebarExport,
  },
];

const { activeApplication } = useApplications();

const itemSelectedIndex = ref();

const componentSelected = computed(() => items[itemSelectedIndex.value]?.component);
</script>

<template>
  <div v-if="activeApplication" class="relative h-full overflow-visible">
    <div class="flex absolute right-0 h-full justify-end pointer-events-none"
      :class="[
        componentSelected ? 'max-w-[100vw] 2xl:relative' : 'sm:relative',
      ]"
    >
      <div class="flex-shrink-0 w-16 h-full bg-slate-200 flex flex-col items-center gap-4 py-2 pointer-events-auto">
        <div class="flex flex-col gap-1 items-center"
          v-for="({icon, image, label }, index) in items"
          :key="icon"
        >
          <GlueButton
            class="w-12 h-12 rounded-full bg-white transition-all border-white"
            :class="[
              itemSelectedIndex === index ? 'scale-105 drop-shadow-lg border-2' : 'hover:scale-105 hover:drop-shadow-lg',
              icon && '[&_svg]:p-2.5',
            ]"
            :icon="icon"
            :color="FormColors.Cyan"
            :checked="itemSelectedIndex === index"
            :size="ButtonSizes.Custom"
            @clickButton="() => {
              itemSelectedIndex = itemSelectedIndex === index ? null : index;
            }"
          >
            <img v-if="image" :src="image" alt="" class="w-12"/>
          </GlueButton>
          <div class="text-xs"
            :class="[
              itemSelectedIndex === index ? 'text-cyan-600 font-semibold' : 'text-slate-500',
            ]"
          >{{ label }}</div>
        </div>
      </div>
      <div class="pointer-events-auto relative bg-white flex-shrink flex flex-col items-start overflow-hidden overflow-y-auto transition-all border-l-indigo-100 border-l"
        :class="[
          componentSelected ? 'w-gc-agentbar' : 'w-0',
        ]"
      >
        <div class="absolute w-full top-0 left-0 p-4 pl-8 flex items-center justify-between z-50">
          <div class="text-sm text-slate-500">{{ items[itemSelectedIndex]?.label }}</div>
          <GlueButton
            icon="x_icon"
            @clickButton="() => {
              itemSelectedIndex = null;
            }"
          />
        </div>
        <Transition name="fade" mode="out-in" duration="150">
          <component v-if="componentSelected" :is="componentSelected" :key="itemSelectedIndex" />
        </Transition>
      </div>
    </div>
  </div>
</template>
