<script setup>
import { computed } from 'vue';

import Icon from '@/components_NEW/common/Icon.vue';

import { OnBoardingTypeColors, OnBoardingTypeIcons, OnBoardingTypePatternSizes, OnBoardingTypePatterns, OnBoardingTypes } from '@/constants/application';

const props = defineProps({
  type: {
    type: String,
    default: OnBoardingTypes.NewApplication,
  },
  hideColor: Boolean,
});

const color = computed(() => OnBoardingTypeColors[props.type] || OnBoardingTypeColors[OnBoardingTypes.NewApplication]);
const icon = computed(() => OnBoardingTypeIcons[props.type] || OnBoardingTypeIcons[OnBoardingTypes.NewApplication]);
const backImage = computed(() => `url(/patterns/${OnBoardingTypePatterns[props.type] || OnBoardingTypePatterns[OnBoardingTypes.NewApplication]}.png)`);

</script>

<template>
  <div
    class="relative w-14 h-14 flex items-center justify-center rounded-md"
    :class="!hideColor && color"
  >
    <div
      v-if="!hideColor"
      class="absolute inset-0 bg-blend-multiply rounded-md"
      :style="{
        backgroundImage: backImage,
        backgroundSize: OnBoardingTypePatternSizes[type] || 'auto',
      }"
    />
    <div class="relative z-10 w-11 h-11 flex items-center justify-center rounded-full bg-white">
      <Icon class="w-7 h-7" :icon="icon" />
    </div>
  </div>  
</template>