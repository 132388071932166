<script setup>
import { onMounted, ref, watch } from 'vue';

import useGlueCharmServices from './composables_NEW/useGlueCharmServices';
import useApplications from '@/composables_NEW/useApplications';
import useUsers from './composables_NEW/useUsers';
import useLanguages from './composables_NEW/useLanguages';

import FatalError from '@/components_NEW/common/FatalError.vue';
import MenuBar from '@/components_NEW/menu/MenuBar.vue';
import ApplicationHeader from '@/components_NEW/application/ApplicationHeader.vue';
import UserPendingInvitationsPopup from '@/components_NEW/common/UserPendingInvitationsPopup.vue';
import AgentSidebar from '@/components_NEW/agent/AgentSidebar.vue';

const { setLanguage } = useLanguages();
const { error500, isUserAuthenticated, initializeLibrary } = useGlueCharmServices();
const { readOnlyMode } = useApplications();
const { activeUser } = useUsers();

const activeViewRef = ref();
const isInitialized = ref(false);
const pendingInvitationsPopupRef = ref();

async function initialize () {
  try {
    await initializeLibrary();
    await setLanguage();

    isInitialized.value = true;
  } catch (err) {
    error500.value = err;
  }
}

watch(activeUser, (newVal, oldVal) => {
  if (activeUser.value && (oldVal?.id !== newVal?.id)) {
    if (activeUser.value.invitations?.length) {
      pendingInvitationsPopupRef.value.open();
    }
  }
});

onMounted(() => {
  initialize();
});
</script>

<template>
  <div id="gc-main" class="fixed inset-0 stroke-gray-800 fill-gray-800 select-none bg-slate-200">
    <div id="gc-teleports" class="absolute inset-0 z-30 pointer-events-none overflow-hidden"/>
    <FatalError
      v-if="!!error500 && isUserAuthenticated"
      class="absolute inset-0 z-40"
      :error-message="error500"
    />
    <div class="absolute font-inter inset-0 overflow-hidden text-slate-700 z-20 flex flex-col sm:flex-row">
      <MenuBar v-if="isUserAuthenticated" />
      <div
        id="container"
        class="relative flex-grow flex flex-col z-10 w-full h-full transition-all overflow-y-auto sm:overflow-hidden border-r border-slate-200"
        :class="[
          readOnlyMode ? 'bg-red-100' : 'bg-slate-100',
        ]"
      >
        <ApplicationHeader class="flex-shrink-0 relative z-30"/>
        <div class="relative w-full sm:flex-grow sm:overflow-hidden flex justify-between">
          <router-view v-slot="{ Component }">
            <transition name="view-transition" mode="out-in">
              <component ref="activeViewRef" :is="Component" class="w-full min-h-full px-2 sm:px-12 sm:overflow-y-scroll py-8"/>
            </transition>
          </router-view>
          <AgentSidebar class="z-30" />
        </div>
      </div>
    </div>
  </div>
  <UserPendingInvitationsPopup ref="pendingInvitationsPopupRef"/>
</template>

<style lang="postcss">
  @import "@/styles/fade.css";
  @import "@/styles/scrollbar.css";

  .view-transition-enter-active,
  .view-transition-leave-active {
    transition: opacity 0.35s ease;
  }
  .view-transition-enter-from,
  .view-transition-leave-to {
    opacity: 0;
  }

  .view-transition-enter-from {
    transition-delay: 0.75s;
  }
</style>
