<script setup>
import { ref, computed, watch, watchEffect } from 'vue';

import { useMagicKeys } from '@vueuse/core';

import EyeIcon from '@/svg/eye.svg';
import EyeCloseIcon from '@/svg/eye_close.svg';
import SearchIcon from '@/svg/search.svg';

import Tooltip from './Tooltip.vue';
import GlueLabel from './GlueLabel.vue';

import { InputTypes, InputCases, FormThemes, FormColors, FormStyles, FormBorderStyles } from '@/constants/forms';
import { getNewTestId, toCamelCase, toKebabCase, toSnakeCase, toUpperCase } from '@/utils/utils';

const { arrowdown, arrowup, enter } = useMagicKeys();

const emit = defineEmits(['update:modelValue', 'onSelectPromptValue', 'onEnter', 'blur']);

const forceCaseFunction = {
  [InputCases.SnakeCase]: toSnakeCase,
  [InputCases.CamelCase]: toCamelCase,
  [InputCases.KebabCase]: toKebabCase,
  [InputCases.Uppercase]: toUpperCase,
};

const props = defineProps({
  modelValue: String,
  label: String,
  id: String,
  placeholder: String,
  errorMessage: String,
  required: Boolean,
  disabled: Boolean,
  forceCase: String,
  maxChars: Number,
  color: {
    type: String,
    default: FormColors.Black,
  },
  style: {
    type: String,
    default: FormStyles.Outline,
  },
  borderStyle: {
    type: String,
    default: FormBorderStyles.OnlyBottom,
  },
  availableProps: {
    type: Array,
    default: () => [],
  },
  type: {
    type: String,
    default: InputTypes.Text,
  },
  enableAutocomplete: {
    type: Boolean,
    default: false,
  },
  hideRequired: {
    type: Boolean,
    default: false,
  },
});

const inputDom = ref();
const inputModel = ref();
const errorTooltipRef = ref();
const showPassword = ref(false);
const isInFocus = ref(false);
const preselectedPromptIndex = ref(-1);

const inputId = computed(() => toKebabCase(props.id || props.label || props.type));
const dataTest = computed(() => getNewTestId(props.id || (`${props.label || props.type}${props.label || props.type ? '-' : ''}input`)));
const value = computed(() => inputModel.value);
const eyeIconActive = computed(() => showPassword.value ? EyeIcon : EyeCloseIcon);
const showPromptMenu = computed(() => isInFocus.value && !!promptMenuItems.value?.length);
const preselectedPrompValue = computed(() => promptMenuItems.value[preselectedPromptIndex.value]);
const formColor = computed(() => props.disabled ? FormColors.Disabled : errorTooltipRef.value?.showing ? FormColors.Red : props.color);

const promptMenuItems = computed(() => props.availableProps.filter(value => (
  !inputModel.value || (value !== inputModel.value && value.indexOf(inputModel.value) === 0)),
));

const inputType = computed(() => {
  return props.type === InputTypes.HiddenText
  || props.type === InputTypes.Password && showPassword.value
  || props.type === InputTypes.Search
    ? InputTypes.Text : props.type;
});

function onInput () {
  if (props.maxChars) {
    inputModel.value = inputModel.value.substring(0, props.maxChars);
  }
  if (props.forceCase) {
    inputModel.value = forceCaseFunction[props.forceCase](inputModel.value);
  }
  emit('update:modelValue', inputModel.value || undefined);
}

function hideError () {
  if (errorTooltipRef.value) {
    errorTooltipRef.value.hide();
  }
}

function showError (message) {
  errorTooltipRef.value.show(message || props.errorMessage, {
    top: props.label ? 25 : 0,
    left: inputDom.value?.offsetWidth / 2,
  });
}

function validate () {
  const isValid = inputDom.value.validity.valid;

  if (!isValid && props.errorMessage) {
    showError();
  }

  return isValid;
}

function clear () {
  inputModel.value = '';
}

function showHidePassword () {
  showPassword.value = !showPassword.value;
}

function onPressKey(event) {
  if (event.key === 'Enter') {
    emit('onEnter');
  }
}

function onFocusInput() {
  hideError();
  preselectedPromptIndex.value = -1;
  isInFocus.value = true;
}

function onBlurInput() {
  isInFocus.value = false;
  emit('blur');
}

function onClickPromptMenuItem(promptValue) {
  inputModel.value = promptValue;
  emit('onSelectPromptValue', promptValue);
}

function focus() {
  inputDom.value.focus();
}

watchEffect(() => {
  if (showPromptMenu.value) {
    let inc = 0;
    if (enter.value) {
      onClickPromptMenuItem(promptMenuItems.value[preselectedPromptIndex.value || 0]);
    } else if (arrowdown.value) {
      inc = +1;
    } else if (arrowup.value) {
      inc = -1;
    }
    preselectedPromptIndex.value = Math.max(-1, Math.min(promptMenuItems.value.length-1, (preselectedPromptIndex.value || 0) + inc));
  }
});

watch(() => props.modelValue, () => {
  inputModel.value = props.modelValue;
}, {immediate: true});

defineExpose({
  value,
  clear,
  validate,
  showError,
  focus,
});
</script>

<template>
  <div
    class="gc-input relative"
    ref="rootDom"
  >
    <Tooltip
      ref="errorTooltipRef"
      :auto-hide="3000"
      :close-button="false"
      :message="errorMessage"
    />
    <GlueLabel v-if="label" :label="label" class="-mb-1 relative z-20" />
    <input
      data-hj-allow
      ref="inputDom"
      :id="inputId"
      :data-test="dataTest"
      :autocomplete="enableAutocomplete ? 'on' : inputType === InputTypes.Password ? 'new-password' : 'off'"
      class="gc-input w-full transition-colors border border-slate-300 min-h-8 min-w-10"
      :class="[
        label ? 'mt-0.5' : 'mt-0',
        type === InputTypes.Password ? 'pr-10' : type === InputTypes.Search ? 'pl-10' : '',
        FormThemes[formColor][style],
        borderStyle,
        borderStyle === FormBorderStyles.Straight && 'px-3',
      ]"
      :style="{
        textSecurity: type === InputTypes.HiddenText && !showPassword ? 'disc' : 'none',
        fontSize: 'inherit',
      }"
      :required="required"
      :disabled="disabled"
      :type="inputType"
      :name="inputId"
      :placeholder="preselectedPrompValue || placeholder"
      v-model="inputModel"
      @input="onInput"
      @focus="onFocusInput"
      @blur="onBlurInput"
      @keypress="onPressKey"
    />
    <div
      class="absolute bottom-2 left-2 flex items-center cursor-pointer z-10 w-7 h-4"
      v-if="type === InputTypes.Search"
    >
      <SearchIcon
        class="w-full h-full"
      />
    </div>
    <div
      class="absolute bottom-2 right-2 flex items-center cursor-pointer z-10 w-7 h-4"
      v-if="type === InputTypes.Password || type === InputTypes.HiddenText"
      @click="showHidePassword"
    >
      <component
        class="w-full h-full"
        :is="eyeIconActive"
      />
    </div>
    <div
      v-if="showPromptMenu"
      class="
        absolute top-9 left-0 min-w-full flex flex-col p-2 z-30
        items-start justify-center shadow-md
      "
    >
      <div
        class="cursor-pointer hover:bg-gray-50 w-full py-1"
        :class="[
          preselectedPromptIndex === index ? 'bg-gray-50' : 'bg-white',
        ]"
        v-for="(promptValue, index) in promptMenuItems"
        :key="index"
        @mousedown="() => onClickPromptMenuItem(promptValue)"
      >
        {{ promptValue }}
      </div>
    </div>
  </div>
</template>

<style lang="postcss">
  input {
    outline: none;
    &:-internal-autofill-selected,
    &:autofill {
      background-clip: text;
      -webkit-text-fill-color: rgb(51 65 85) !important;
      font-size: 14px !important;
      font-weight: normal !important;
      font-family: Inter, sans-serif !important;
    }
  }
</style>
