
<script setup>
import { ref, watch } from 'vue';
import { useRouter } from 'vue-router';

import { onClickOutside } from '@vueuse/core';

import useLanguages from '@/composables_NEW/useLanguages';

import GlueMenuButton from '@/components_NEW/formFields/GlueMenuButton.vue';
import MenuBarItem from './MenuBarItem.vue';

import { ButtonSizes, FormBorderStyles, FormColors, FormStyles } from '@/constants/forms';

const router = useRouter();
const { t } = useLanguages();

const props = defineProps({
  label: String,
  icon: String,
  isClosed: Boolean,
  link: Object,
  dynamicLink: Function,
  action: Function,
  options: Object,
});

const rootDom = ref();
const optionsMenuButtonRef = ref();
const submenuHidden = ref(true);

onClickOutside(rootDom, () => {
  submenuHidden.value = true;
});

function onSelectOption(optionId) {
  if (props.action) {
    props.action(optionId);
  } else if (props.dynamicLink) {
    router.push(props.dynamicLink(optionId));
  }
}

watch(submenuHidden, () => {
  if (submenuHidden.value) {
    optionsMenuButtonRef.value.closeMenu();
  } else {
    optionsMenuButtonRef.value.openMenu();
  }
});
</script>

<template>
  <div
    ref="rootDom"
    class="relative"
  >
    <MenuBarItem
      class="w-42 flex-grow"
      v-bind="props"
      :label="isClosed ? t('Change Workspace') : label"
      :right-space="true"
      :action="() => {
        submenuHidden = !submenuHidden;
      }"
    />
    <GlueMenuButton
      v-if="options"
      ref="optionsMenuButtonRef"
      class="absolute top-0 w-6 mt-1.5 flex-shrink pointer-events-none"
      :class="[
        isClosed ? '-right-7' : 'right-0'
      ]"
      :size="ButtonSizes.Mini"
      :border-style="FormBorderStyles.Straight"
      :hide-arrow="isClosed"
      :style="FormStyles.Naked"
      :color="FormColors.Black"
      :items="options"
      :disable-outside-close="true"
      @click-menu-item="onSelectOption"
    />

  </div>
</template>
