import { ref } from 'vue';

import * as Sentry from '@sentry/vue';

import { createGtm } from '@gtm-support/vue-gtm';

import { AnalyticsBrowser } from '@june-so/analytics-next';

import { viteEnv } from '@/constants/common';

const GTMId = 'GTM-WRJK6GQ';
const juneId = '9IfGd6B3h8uj6JVd';

const juneAnalytics = ref();
const disabled = ref();

function initGTM(router, isProd) {
  return createGtm({
    id: GTMId,
    defer: true,
    enabled: isProd,
    debug: false,
    vueRouter: router,
    trackOnNextTick: false,
  });
}

function initSentry(appObj, router) {
  Sentry.init({
    // eslint-disable-next-line no-undef
    release: 'gluecharm-admin@v' + __APP_VERSION__,
    app: appObj,
    dsn: 'https://ca4321ceed20494bb9efeffb5d57c26d@o4504507946893312.ingest.sentry.io/4505275195981824',
    integrations: [
      new Sentry.BrowserTracing({
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['localhost', /^https:\/\/app\.gluecharm\.info/, /^https:\/\/dev\.gluecharm\.info/],
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    environment: viteEnv,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

function initJune() {
  juneAnalytics.value = AnalyticsBrowser.load({
    writeKey: juneId,
  });
}

function initAnalytics({appObj, router, isProd}) {
  if (isProd) {
    const gtmObj = initGTM(router, isProd);
    initSentry(appObj, router);
    initJune();

    return gtmObj;
  } else {
    disabled.value = true;
    return false;
  }
}

function identifyUser({email, id}) {
  if (!disabled.value) {
    Sentry.setUser({id, email});

    juneAnalytics.value.identify(id, {
      email,
    });
  }
}

function identifyWorkspace({name, id}) {
  if (!disabled.value && juneAnalytics.value) {
    juneAnalytics.value.group(id,{
      name,
    });
  }
}

function captureMessage(errorLiteral) {
  if (!disabled.value) {
    Sentry.captureMessage(errorLiteral);
  }
}

function captureError(errorLiteral) {
  if (!disabled.value) {
    Sentry.captureException(new Error(errorLiteral));
  }
}

function trackEvent(eventName, params) {
  if (!disabled.value) {
    juneAnalytics.value.track(eventName, {...params});
  }
}

function trackNavigation({pageName}) {
  if (!disabled.value) {
    juneAnalytics.value.page(pageName);
  }
}

export default function useAnalytics() {
  return {
    initAnalytics,
    identifyUser,
    identifyWorkspace,
    trackEvent,
    trackNavigation,
    captureError,
    captureMessage,
  };
}
