<script setup>
import useLanguages from '@/composables_NEW/useLanguages';

import Logo from '@/svg/gluecharm_logo.svg';
import IconWarning from '@/svg/warning.svg';

import GcEmailLink from './GcEmailLink.vue';

defineProps({
  errorMessage: String,
});

const { t } = useLanguages();
</script>

<template>
  <Transition name="fade" appear>
    <div class="bg-white flex justify-center items-center">
      <Logo class="h-10 absolute top-4 left-10"/>
      <div class="
        text-red-600 font-medium relative w-full max-w-lg p-10 rounded-2xl
        text-center flex flex-col gap-4 border-red-600 border-spacing-4 border-8
      ">
        <IconWarning class="absolute -top-11 -left-11 w-20 h-20 fill-red-600 stroke-white"/>
        <p class="flex text-2xl font-black mb-5 items-center justify-center gap-2">
          {{ t('Warning') }}!!!
        </p>
        <p>{{ t('There are errors connecting with the server, please check your internet connection and reload the page') }}</p>
        <p>{{ t('If the error persists, please contact Gluecharm Technical Service to assist you.') }}</p>
        <p class="bg-red-600 text-xs p-4 text-white select-text">{{ errorMessage }}</p>
        <GcEmailLink />
      </div>
    </div>
  </Transition>
</template>
