<script setup>
import { computed, defineAsyncComponent } from 'vue';

const props = defineProps({
  icon: String,
});

const iconComponent = computed(() => {
  return props.icon && defineAsyncComponent(() => import(`../../svg/${props.icon}.svg`));
});
</script>

<template>
  <component
    v-if="iconComponent"
    :is="iconComponent"
  />
</template>
