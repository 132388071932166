import { readonly, ref, computed } from 'vue';

import useGlueCharmServices from '@/composables_NEW/useGlueCharmServices';
import useLocalEdition from '@/composables_NEW/useLocalEdition';

import { sortItemsByCreatedAt } from '@/utils/utils';
import { DataAssetTypes } from '@/constants/dataAssets';

const { fetchGlueCharmMethod } = useGlueCharmServices();

const cloudPlatformConfigurations = ref({});
const cloudPlatformConfigurationsOrder = computed(() => sortItemsByCreatedAt(cloudPlatformConfigurations.value));

const {
  editedItems: editedCloudPlatformConfigurations,
  setItems: setCloudPlatformConfigurations,
  updateLocalItem: updateLocalCloudPlatformConfiguration,
  removeItemFromEditedItems: removeItemFromEditedCloudPlatformConfigurations,
  cancelLocalItemChanges: cancelLocalCloudPlatformConfigurationChanges,
  deleteLocalItem: deleteLocalCloudPlatformConfiguration,
  saveLocalItemChanges: saveLocalChangedCloudPlatformConfiguration,
} = useLocalEdition({
  entity_name: DataAssetTypes.CloudPlatformConfigurations,
  items: cloudPlatformConfigurations,
  services: {
    create: 'cloud-plaform-configurator-manager/create',
    update: 'cloud-plaform-configurator-manager/update',
    delete: 'cloud-plaform-configurator-manager/delete',
  },
  callbacks: {
    create: async (newCloudId) => {
      const newCloudOrgId = cloudPlatformConfigurations.value[newCloudId].organization_id;
      await getCloudPlatformConfigurationsList(newCloudOrgId);
    },
  },
});

async function getCloudPlatformConfigurationsList(orgId) {
  const response = await fetchGlueCharmMethod('cloud-plaform-configurator-manager/list', `getCloudPlatformConfigurationsList-${orgId}`, {
    organization_id: orgId,
  });
  
  if (response.cloud_platform_configurations) {
    setCloudPlatformConfigurations(response.cloud_platform_configurations);
    return true;
  }

  return false;
}

export default function useCloudPlatformConfigurations() {
  return {
    cloudPlatformConfigurations: readonly(cloudPlatformConfigurations),
    editedCloudPlatformConfigurations: readonly(editedCloudPlatformConfigurations),
    cloudPlatformConfigurationsOrder: readonly(cloudPlatformConfigurationsOrder),
    getCloudPlatformConfigurationsList,
    setCloudPlatformConfigurations,
    updateLocalCloudPlatformConfiguration,
    removeItemFromEditedCloudPlatformConfigurations,
    deleteLocalCloudPlatformConfiguration,
    cancelLocalCloudPlatformConfigurationChanges,
    saveLocalChangedCloudPlatformConfiguration,
  };
}
