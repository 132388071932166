import { readonly, ref, computed, watch } from 'vue';

import { v4 as uuidv4 } from 'uuid';

import useGlueCharmServices from '@/composables_NEW/useGlueCharmServices';
import useFetching from '@/composables_NEW/useFetching';
import useDataAssets from '@/composables_NEW/useDataAssets';

import { deepEqualData } from '@/utils/utils';
import { DataAssetTypes } from '@/constants/dataAssets';
import { HistoryChangeTypes } from '@/constants/common';

const { isUserAuthenticated, fetchGlueCharmMethod } = useGlueCharmServices();
const { fetchingActions, fetchingErrors, addFetchingAction, setFetchingActionDone, addFetchingError } = useFetching();
const { dataAssets, addDataAsset } = useDataAssets();

export default function useLocalEdition({
  entity_name,
  items,
  callbacks: {
    cancel: onCancelCallBack,
    beforeSave: onBeforeSaveCallback,
    create: onCreateCallback,
    update: onUpdateCallback,
    beforeDelete: onBeforeDeleteCallback,
    delete: onDeleteCallback,
  } = {},
}) {

  const editedItems = ref({});

  const newItemId = computed(() => {
    const existingNewItem = Object.values(items.value).find(item => !item.created_at);
    return existingNewItem?.id || uuidv4();
  });
  
  function clearItems(filterFunction = () => true) {
    Object.values(items.value || []).filter((itemData) => filterFunction(itemData)).forEach(itemData => {
      if (itemData.created_at) {
        if (editedItems.value[itemData.id]) {
          removeItemFromEditedItems(itemData.id);
        }
        delete items.value[itemData.id];
      }
    });
  }

  function setItemData(itemId, itemData) {
    items.value[itemId] = {
      ...itemData,
    };
  }

  function setItems(newItems) {
    (newItems || []).forEach(itemData => {
      if (editedItems.value[itemData.id]) {
        removeItemFromEditedItems(itemData.id);
      }

      setItemData(itemData.id, {
        ...itemData,
      });
    });
  }
  
  function addEditedItem(itemId) {
    editedItems.value[itemId] = items.value[itemId] || {};
  }

  function removeItemFromEditedItems(itemId) {
    delete editedItems.value[itemId];
  }

  function removeItemFromRefItems(itemId) {
    if(onBeforeDeleteCallback) {
      onBeforeDeleteCallback(itemId);
    }

    delete items.value[itemId];

    if (onDeleteCallback) {
      onDeleteCallback(itemId);
    }
  }

  function updateLocalItem(itemId, newData) {    
    if (!editedItems.value[itemId]) {
      addEditedItem(itemId);
    }
  
    setItemData(itemId, {
      ...items.value[itemId],
      ...newData,
    });

    if (deepEqualData(items.value[itemId], editedItems.value[itemId], ['editedAt'])) {
      removeItemFromEditedItems(itemId);
    } else {
      editedItems.value[itemId].editedAt = Date.now();
    }
  }
  
  function removeItemFromList(itemId) {
    removeItemFromRefItems(itemId);
    removeItemFromEditedItems(itemId);
  }

  function deleteLocalItem(itemId, onlyRemoveFromList = false) {
    if (items.value[itemId]) {
      if (!editedItems.value[itemId]) {
        editedItems.value[itemId] = items.value[itemId];
      } 
      if (!editedItems.value[itemId].created_at || onlyRemoveFromList) {
        removeItemFromList(itemId);
        return;
      }
  
      items.value[itemId].__deleted__ = true;
    }
  }

  function cancelLocalItemChanges(itemId) {
    if (items.value[itemId]?.__deleted__) {
      delete items.value[itemId].__deleted__;
    } else if (editedItems.value[itemId]) {
      delete editedItems.value[itemId].editedAt;
      if (deepEqualData(editedItems.value[itemId], {})) {
        removeItemFromRefItems(itemId);
      } else {
        setItemData(itemId, editedItems.value[itemId]);
      }
    }
    
    removeItemFromEditedItems(itemId);

    if (onCancelCallBack) {
      onCancelCallBack(itemId);
    }
  }

  async function saveLocalItemChanges(itemId) {
    const fetchingAction = `saveLocalItemChanges-${itemId}`;

    if (onBeforeSaveCallback) {
      await onBeforeSaveCallback(itemId);
    }
  
    const itemData = items.value[itemId];

    if (!itemData) return;

    const {
      created_at,
      __deleted__,
      ...restParams
    } = itemData;
    
    if (editedItems.value[itemId] || !created_at) {
      const forceUpdateOrganization = shouldForceUpdateOrganization(itemData);
  
      const actionType = getActionType(__deleted__, created_at);

      if (!actionType) return;

      if (actionType === HistoryChangeTypes.Create && fetchingActions.value[`${actionType}-${itemId}`]) {
        return true;
      }
  
      try {
        addFetchingAction(fetchingAction);
        
        const actionResponse = await performAction(actionType, itemId, itemData, restParams);
  
        if (actionResponse) {
          removeItemFromEditedItems(itemId);
          setFetchingActionDone(fetchingAction);
          return true;
        } else {
          addFetchingError(fetchingAction, fetchingErrors.value[`${actionType}-${itemId}`]);
          return false;
        }
      } catch (error) {
        addFetchingError(fetchingAction, error.message);
        return false;
      } finally {
        if (forceUpdateOrganization) {
          dataAssets.value[DataAssetTypes.Organizations].onUpdateCallback(forceUpdateOrganization);
        }

        if (itemData.ownership_application_id) {
          dataAssets.value[DataAssetTypes.Applications].onUpdateCallback(itemData.ownership_application_id);
        }
      }
    }

    return true;
  }
  
  function shouldForceUpdateOrganization(itemData) {
    return (
      itemData.organization_id ||
      [DataAssetTypes.Environments, DataAssetTypes.Services].includes(entity_name) &&
      dataAssets.value[DataAssetTypes.Applications].items[itemData.application_id]?.organization_id
    );
  }
  
  function getActionType(isDeleted, createdAt) {
    if (isDeleted) return HistoryChangeTypes.Delete;
    if (!createdAt) return HistoryChangeTypes.Create;
    return HistoryChangeTypes.Update;
  }
  
  async function performAction(actionType, itemId, itemData, restParams) {
    const fetchingAction = `${actionType}-${itemId}`;

    addFetchingAction(fetchingAction);

    let actionResponse;
    switch (actionType) {
      case HistoryChangeTypes.Delete:
        actionResponse = await fetchGlueCharmMethod('delete-generic-entity', fetchingAction, {
          entity_name,
          id: itemId,
        }, 'gluex');
        console.log('DELETE actionResponse: ', actionResponse);
        if (actionResponse?.success) {
          removeItemFromRefItems(itemId);
        }
        return actionResponse?.success;
  
      case HistoryChangeTypes.Create:
      case HistoryChangeTypes.Update:
        actionResponse = await fetchGlueCharmMethod('save-generic-entity', fetchingAction, {
          entity_name,
          entity: restParams,
        }, 'gluex');
        console.log('CREATE/UPDATE actionResponse: ', actionResponse);
        if (actionResponse) {
          if (actionType === HistoryChangeTypes.Create) {
            if(onCreateCallback) {
              await onCreateCallback(itemId);
            }
            await getItem(itemId);
          }
          if (actionType === HistoryChangeTypes.Update && onUpdateCallback) {
            await onUpdateCallback(itemId, editedItems.value[itemId], items.value[itemId]);
          }
        }
        return actionResponse?.success;

      default:
        return false;
    }
  }
  
  async function getItem(id) {
    const response = await fetchGlueCharmMethod('get-generic-entity', `getItem-${id}`, {
      entity_name,
      id,
    }, 'gluex');
    
    console.log(`${entity_name} -> getItem response: `, response);
    if (response?.data?.entity) {
      setItemData(id, response?.data?.entity);
    }
  
    return response?.success;
  }
  
  async function listItemsByKeyValue({key, value}, fetchingAction) {
    const response = await fetchGlueCharmMethod('list-generic-entities', fetchingAction, {
      entity_name,
      key,
      value,
    }, 'gluex');
    
    console.log(`${entity_name} -> listItemsByKeyValue response: `, response);
    if (response?.data?.entities) {
      setItems(response?.data?.entities);
    }
  
    return response?.success;
  }
  
  const itemObj = {
    items: readonly(items),
    editedItems: readonly(editedItems),
    newItemId,
    getItem,
    listItemsByKeyValue,
    setItems,
    clearItems,
    removeItemFromEditedItems,
    updateLocalItem,
    deleteLocalItem,
    cancelLocalItemChanges,
    saveLocalItemChanges,
  };
  
  addDataAsset(entity_name, itemObj);

  watch(isUserAuthenticated, () => {
    if (!isUserAuthenticated.value) {
      clearItems();
    }
  });
  
  return itemObj;
}
