import { ServiceTypes } from './common';

export const DataAssetTypes = {
  Applications: 'applications',
  DataTypes: 'data_types',
  Entities: 'entities',
  Enums: 'enums',
  ImplementableEntities: 'implementable_entities',
  Services: 'services',
  NewFlowServices: 'new_flow_services',
  Methods: 'methods',
  MethodMessages: 'method_messages',
  Events: 'events',
  NewFlowEvents: 'new_flow_events',
  UserStories: 'user_stories',
  Flows: 'flows',
  NewFlows: 'new_flows',
  Environments: 'environments',
  VcrConfigurations: 'vcr_configurations',
  CloudPlatformConfigurations: 'cloud_platform_configurations',
  Organizations: 'workspaces',
  DiagramsVersions: 'diagram_versions',

  Actors: 'actor',
  CustomerJourneys: 'customer_journey',
  Features: 'feature',
  Usecases: 'use_case',
};

export const DataAssetTypeLiterals = {
  [DataAssetTypes.Organizations]: ['Workspace', 'Organizations'],
  [DataAssetTypes.CloudPlatformConfigurations]: ['Cloud Platform Configuration', 'Cloud Platform Configurations'],
  [DataAssetTypes.VcrConfigurations]: ['Vcr Configuration', 'Vcr Configurations'],
  [DataAssetTypes.Applications]: ['Application', 'Applications'],
  [DataAssetTypes.Environments]: ['Environment', 'Environments'],
  [DataAssetTypes.Usecases]: ['Usecase', 'Usecases'],
  [DataAssetTypes.UserStories]: ['User Story', 'User Stories'],
  [DataAssetTypes.Flows]: ['Flow', 'Flows'],
  [DataAssetTypes.NewFlows]: ['New Flow', 'New Flows'],
  [DataAssetTypes.Actors]: ['User', 'Users'],
  [DataAssetTypes.Services]: ['Service', 'Services'],
  [DataAssetTypes.NewFlowServices]: ['Service', 'Services'],
  [DataAssetTypes.Methods]: ['Method', 'Methods'],
  [DataAssetTypes.Events]: ['Event', 'Events'],
  [DataAssetTypes.NewFlowEvents]: ['Event', 'Events'],
  [DataAssetTypes.MethodMessages]: ['Method Message', 'Method Messages'],
  [DataAssetTypes.DataTypes]: ['Data Type', 'Data Types'],
  [DataAssetTypes.Entities]: ['Entity', 'Entities'],
  [DataAssetTypes.Enums]: ['Enumeration', 'Enumerations'],
  [DataAssetTypes.ImplementableEntities]: ['Implementable Entity', 'Implementable Entities'],
  [DataAssetTypes.DiagramVersions]: ['Diagrams Version', 'Diagrams Versions'],
};

export const DataAssetTypeIcons = {
  [DataAssetTypes.Organizations]: 'organization',
  [DataAssetTypes.CloudPlatformConfigurations]: 'cloud_platform_configuration',
  [DataAssetTypes.VcrConfigurations]: 'vcr_configuration',
  [DataAssetTypes.Applications]: 'application',
  [DataAssetTypes.Environments]: 'environment',
  [DataAssetTypes.Usecases]: 'usecase',
  [DataAssetTypes.UserStories]: 'usecase',
  [DataAssetTypes.Flows]: 'flow',
  [DataAssetTypes.Actors]: 'multiple_actors',
  [DataAssetTypes.Services]: 'service',
  [DataAssetTypes.NewFlowServices]: 'service',
  [DataAssetTypes.Methods]: 'method',
  [DataAssetTypes.Events]: 'browsers',
  [DataAssetTypes.NewFlowEvents]: 'browsers',
  [DataAssetTypes.MethodMessages]: 'browsers',
  [DataAssetTypes.Entities]: 'entity',
  [DataAssetTypes.Enums]: 'enum',
  [DataAssetTypes.ImplementableEntities]: 'implementable_entity',
  [DataAssetTypes.DiagramsVersions]: 'history',
};

export const DataModelTypes = [DataAssetTypes.Entities, DataAssetTypes.Enums, DataAssetTypes.ImplementableEntities];

export const DataModelsMenuItems = DataModelTypes.reduce((itemsObj, type) => ({
  ...itemsObj,
  [DataAssetTypeLiterals[type][0]]: type,
}), {});

export const ApplicationAssets = [
  DataAssetTypes.Actors,
  DataAssetTypes.Entities,
  DataAssetTypes.Enums,
  DataAssetTypes.Environments,
  DataAssetTypes.Events,
  DataAssetTypes.Services,
  DataAssetTypes.Usecases,
  DataAssetTypes.UserStories,
];

export const ApplicationSubAssets = {
  [DataAssetTypes.Services]: [
    DataAssetTypes.Methods,
  ],
  [DataAssetTypes.Methods]: [
    DataAssetTypes.MethodMessages,
  ],
  [DataAssetTypes.Usecases]: [
    DataAssetTypes.Flows,
  ],
};

export const DiffTypes = {
  Created: 'created',
  Deleted: 'deleted',
  Updated: 'updated',
};

export const DiffTypeStyles = {
  [DiffTypes.Updated]: 'bg-indigo-600',
  [DiffTypes.Created]: 'bg-green-600',
  [DiffTypes.Deleted]: 'bg-red-600',
};

export const DefaultFrontendService = {
  name: 'Frontend Application',
  shortname: 'fro',
  description: 'Service for the frontend application API',
  type: ServiceTypes.Frontend,
};
