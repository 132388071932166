import { readonly, ref, computed } from 'vue';

import useGlueCharmServices from '@/composables_NEW/useGlueCharmServices';
import useLocalEdition from '@/composables_NEW/useLocalEdition';

import { sortItemsByCreatedAt } from '@/utils/utils';
import { DataAssetTypes } from '@/constants/dataAssets';

const { fetchGlueCharmMethod } = useGlueCharmServices();

const vcrConfigurations = ref({});
const vcrConfigurationsOrder = computed(() => sortItemsByCreatedAt(vcrConfigurations.value));

const {
  editedItems: editedVcrConfigurations,
  setItems: setVcrConfigurations,
  updateLocalItem: updateLocalVcrConfiguration,
  removeItemFromEditedItems: removeItemFromEditedVcrConfigurations,
  cancelLocalItemChanges: cancelLocalVcrConfigurationChanges,
  deleteLocalItem: deleteLocalVcrConfiguration,
  saveLocalItemChanges: saveLocalChangedVcrConfiguration,
} = useLocalEdition({
  entity_name: DataAssetTypes.VcrConfigurations,
  items: vcrConfigurations,
  services: {
    create: 'vcr-configurator-manager/create',
    update: 'vcr-configurator-manager/update',
    delete: 'vcr-configurator-manager/delete',
  },
  callbacks: {
    create: async (newVcrId) => {
      const newVcrOrgId = vcrConfigurations.value[newVcrId].organization_id;
      await getVcrConfigurationsList(newVcrOrgId);
    },
  },
});

async function getVcrConfigurationsList(orgId) {
  const response = await fetchGlueCharmMethod('vcr-configurator-manager/list', `getVcrConfigurationsList-${orgId}`, {
    organization_id: orgId,
  });
  
  if (response.vcr_configurations) {
    setVcrConfigurations(response.vcr_configurations);
    return true;
  }

  return false;
}

export default function useVcrConfigurations () {
  return {
    vcrConfigurations: readonly(vcrConfigurations),
    editedVcrConfigurations: readonly(editedVcrConfigurations),
    vcrConfigurationsOrder: readonly(vcrConfigurationsOrder),
    updateLocalVcrConfiguration,
    removeItemFromEditedVcrConfigurations,
    cancelLocalVcrConfigurationChanges,
    deleteLocalVcrConfiguration,
    saveLocalChangedVcrConfiguration,
    getVcrConfigurationsList,
    setVcrConfigurations,
  };
}
