export const TableBorderStyles = {
  Rounded: 'rounded',
  Straight: 'straight',
};

export const TableColumnTypes = {
  Text: 'text',
  Links: 'links',
  Actions: 'actions',
  List: 'list',
  Icon: 'icon',
  Hidden: 'hidden',
  OnlyExport: 'onlyExport',
};

export const LinkTypes = {
  Internal: 'internal',
  External: 'external',
  Action: 'action',
  Disabled: 'none',
};
