<script setup>
import { computed, ref } from 'vue';

import useLanguages from '@/composables_NEW/useLanguages';
import useApplications from '@/composables_NEW/useApplications';

import Popup from '@/components_NEW/common/Popup.vue';
import GcEmailLink from '@/components_NEW/common/GcEmailLink.vue';
import GlueButton from '@/components_NEW/formFields/GlueButton.vue';

import { GCMailTypes, PopupSizes } from '@/constants/common';
import { FormStyles } from '@/constants/forms';
import { toSnakeCase } from '@/utils/utils';

defineProps({
  sharePopup: Object,
});

const { t } = useLanguages();
const { activeApplication } = useApplications();

const popupRef = ref();

const jsonFile = computed(() => {
  const fullApplicationStr = JSON.stringify(activeApplication.value);
  return `data:text/json;charset=utf-8,${encodeURIComponent(fullApplicationStr)}`;
});

function open() {
  popupRef.value.open();
}

function close() {
  popupRef.value.close();
}

defineExpose({
  open,
  close,
});
</script>

<template>
  <Popup
    ref="popupRef"
    :size="PopupSizes.Small"
  >
    <div class="flex flex-col items-start">
      <div class="text-xl flex items-center text-slate-800">
        {{ t('Export your technical documentation') }}
      </div>
      <div class="mt-10 text-slate-700 text-sm flex flex-col gap-2">
        <p>{{ t('In beta launch, we are restricting the export of the technical documentation outside of Gluecharm (this will be available in the future though!).') }}</p>
        <p>{{ t('To share your work, please invite others to your project or download the json file of what you created so far.') }}</p>
        <p>{{ t('If you have any ideas of what else you would like to do, please send them to') }}<GcEmailLink class="ml-1" :type="GCMailTypes.Design"/></p>        
      </div>
      <div class="flex gap-4 mt-6">
        <GlueButton
          :label="t('Send invite')"
          @click-button="() => {
            sharePopup.open();
            close();
          }"
        />
        <GlueButton
          :style="FormStyles.Outline"
          :label="t('Download json')"
          :href="jsonFile"
          :download="`${toSnakeCase(activeApplication.name)}.json`"
        />
      </div>
    </div>
  </Popup>
</template>
