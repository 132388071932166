
<script setup>
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { router } from '@/router_NEW';

import useWorkspaces from '@/composables_NEW/useWorkspaces';
import useApplications from '@/composables_NEW/useApplications';
import useGlueCharmServices from '@/composables_NEW/useGlueCharmServices';

import useLanguages from '@/composables_NEW/useLanguages';

import GlueButton from '@/components_NEW/formFields/GlueButton.vue';
import Icon from '@/components_NEW/common/Icon.vue';
import MenuBarGroup from '@/components_NEW/menu/MenuBarGroup.vue';
import OnBoardingPopup from '@/components_NEW/common/OnBoardingPopup.vue';

import { ButtonSizes } from '@/constants/forms';
import { RouteNames } from '@/constants/common';

const { t } = useLanguages(); 
const { workspaces, activeWorkspace } = useWorkspaces();
const { activeApplication } = useApplications();
const { logout } = useGlueCharmServices();

const rootDom = ref();
const containerDom = ref();
const isClosed = ref(true);
const onboardingPopupRef = ref();
const mustAllowScroll = ref();

// eslint-disable-next-line no-undef
const version = computed(() => __APP_VERSION__);

const logoParts = computed(() => {
  if (window.location.href.includes('the.ai.app.for.scoping.info')) {
    return {
      icon: 'theaiscopingapp_icon',
      literal: 'theaiscopingapp_literal',
    };
  }
  return {
    icon: 'gluecharm_icon',
    literal: 'gluecharm_logo_name',
  };
});

const copyString = computed(() => {
  return t('{{year}} - Gluecharm', {
    year: new Date().getFullYear().toString(),
  });
});

const workspacesList = computed(() => Object.values(workspaces.value || {}).reduce((wrkspcsObj, wrkspc) => ({
  ...wrkspcsObj,
  [wrkspc.name]: wrkspc.id,
}), {}));

function checkScroll() {
  if (containerDom.value?.scrollHeight > rootDom.value?.clientHeight - 80) {
    mustAllowScroll.value = true;
  } else {
    mustAllowScroll.value = false;
  }
}

function getApplicationLink(routeName) {
  return {
    name: routeName,
    params: {
      org: activeWorkspace.value?.slug,
      appId: activeApplication.value?.id,
    },
  };
}

const ApplicationMenuItems = computed(() => ([
  {
    items: [{
      label: t('Overview'),
      icon: 'presentation',
      link: getApplicationLink(RouteNames.Application),
    }, {
      label: t('Users'),
      icon: 'user',
      link: getApplicationLink(RouteNames.Users),
    }, {
      label: t('Features'),
      icon: 'list-checks',
      link: getApplicationLink(RouteNames.Features),
    }, {
      label: t('Backlog'),
      icon: 'identification-card',
      link: getApplicationLink(RouteNames.Backlog),
    }, {
      label: t('Views'),
      icon: 'computer_desktop',
      link: getApplicationLink(RouteNames.Views),
    }, {
      label: t('Human Interactions'),
      icon: 'cursor-click',
      link: getApplicationLink(RouteNames.HumanInteractionList),
    }, {
      label: t('Services'),
      icon: 'database',
      link: getApplicationLink(RouteNames.Services),
    }, {
      label: t('Events'),
      icon: 'browsers',
      link: getApplicationLink(RouteNames.Events),
    }, {
      label: t('Data Types'),
      icon: 'article',
      link: getApplicationLink(RouteNames.DataTypes),
    }],
  }, 
]));

const UserMenuItems = computed(() => ([
  {
    name: t('Account'),
    items: [
      {
        label: activeWorkspace.value?.name,
        icon: 'circles-four',
        options: workspacesList.value,
        dynamicLink: (optionId) => ({
          name: RouteNames.Workspace,
          params: {
            org: workspaces.value[optionId]?.slug,
          },
        }),
      },
      {
        label: 'Workspace Settings',
        icon: 'sliders-horizontal',
        link: {
          name: RouteNames.ManageWorkspace,
          params: {
            org: activeWorkspace.value?.slug,
            tab: 'members',
          },
        },
      },
      {
        label: 'Help',
        icon: 'question',
        action: () => {
          onboardingPopupRef.value.open();
        },
      },
      {
        label: 'Logout',
        icon: 'sign-out',
        action: logout,
      },
    ],
  },
]));

onMounted(() => {
  window.addEventListener('resize', checkScroll);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', checkScroll);
});
</script>

<template>
    <div
    class="relative z-20 w-full bg-white transition-all visible sm:hidden border-b border-slate-200"
    :class="[
      isClosed ? 'h-14' : 'h-full'
    ]"
  >
    <div class="h-14 flex items-center justify-between px-2 pointer-events-auto">
      <Icon
        class="h-9 pl-2 cursor-pointer"
        icon="gluecharm_logo"
        @click="() => {
          router.push({
            name: RouteNames.Workspace,
            params: {
              org: router.currentRoute.value.params.org,
            }
          })
        }"
      />
      <GlueButton
        :icon="isClosed ? 'menu' : 'x_icon'"
        @click-button="() => {
          isClosed = !isClosed
        }"
      />
    </div>
    <div
      v-if="!isClosed"
      class="absolute z-50 top-14 bg-white w-full h-screen px-2 py-0 overflow-y-auto bottom-0 pointer-events-auto"
    >
      <MenuBarGroup
        v-if="activeApplication"
        :menu-items="ApplicationMenuItems"
      />
      <MenuBarGroup
        :menu-items="UserMenuItems"
      />
    </div>
  </div>

  <div
    ref="rootDom"
    class=" relative z-20 bg-white font-inter flex-col h-full overflow-visible transition-all hidden sm:flex border-r border-slate-200"
    :class="[
      isClosed ? 'w-12' : 'w-52 overflow-x-hidden'
    ]"
  >
    <div class="relative z-20 flex-shrink-0 p-2 h-24 bg-white">
      <div
        class="absolute top-4 left-1.5 h-9 cursor-pointer"
        @click="() => {
          router.push({
            name: RouteNames.Workspace,
            params: {
              org: router.currentRoute.value.params.org,
            }
          })
        }"
      >
        <Icon
          :icon="logoParts.icon"
          class="absolute h-full flex-grow-0 flex-shrink-0"
        />
        <Icon
          :icon="logoParts.literal"
          class="absolute h-full transition-opacity pointer-events-none"
          :class="[
            isClosed ? 'opacity-0' : 'opacity-100'
          ]"
        />
      </div>
      <GlueButton
        icon="caret-right"
        class="transition-all absolute"
        :class="[
          isClosed ? 'rotate-0 right-2 top-16' : 'rotate-180 right-0.5 top-5',
        ]"
        :size="ButtonSizes.Mini"
        @click-button="() => {
          isClosed = !isClosed;
        }"
      />
    </div>
    <div
      ref="containerDom"
      class="flex-grow w-full flex flex-col gap-4"
      :class="[
        mustAllowScroll ? 'overflow-y-auto overflow-x-hidden' : 'overflow-visible'
      ]"
    >
      <MenuBarGroup
        class="relative transition-all duration-500"
        :class="[
          activeApplication ? 'max-h-96 py-4 delay-500' : 'max-h-0 py-0 overflow-x-hidden delay-500'
        ]"
        :menu-items="ApplicationMenuItems"
        :is-closed="isClosed"
      />
      <MenuBarGroup
        class="relative z-10 pb-12 bg-white"
        :menu-items="UserMenuItems"
        :is-closed="isClosed"
      />
      <div
        class="text-xs flex flex-col p-3 pb-4 mt-auto gap-1 transition-all"
        :class="[
          isClosed && 'hidden',
        ]"
      >
        <span>{{ copyString }}</span>
        <span class="select-text">v{{ version }}</span>
      </div>
    </div>
    <OnBoardingPopup ref="onboardingPopupRef"/>
  </div>
</template>

<style lang="postcss">
@keyframes open-menu {
  0% {
    max-height: 0px;
  }

  100% {
    max-height: 500px;
  }
}

.open-menu {
  &-enter-active {
    animation: open-menu 0.57s;
  }

  &-leave-active {
    animation: open-menu 0.37s 0.2s reverse;
  }
}
</style>
