import { readonly, reactive } from 'vue';
import { activeApplicationId } from '@/router_NEW';

import { v4 as uuidv4 } from 'uuid';

import useGlueCharmServices from '@/composables_NEW/useGlueCharmServices';
import usePersistentData from '@/composables_NEW/usePersistentData';
import useFetching from '@/composables_NEW/useFetching';

import { deepEqualData, isEmptyData, sortItemsByCreatedAt, sortItemsByUpdatedAt } from '@/utils/utils';
import { ApplicationArtifactTypes, ArtifactDataModel, ArtifactFieldBases, ArtifactTypes, WorkspaceArtifactTypes } from '@/constants/artifacts';

const { fetchGlueCharmMethod } = useGlueCharmServices();
const { getPersistentData, savePersistentData } = usePersistentData();
const { fetchingActions } = useFetching();

const DisabledArtifactFields = ['summary', 'version'];

const metaDataFiles = reactive({});

const newArtifactActions = {};

const artifacts = reactive(Object.keys(ArtifactDataModel).reduce((acc, artifactType) => {
  acc[artifactType] = {};
  return acc;
}, {}));

const editedArtifacts = reactive(Object.keys(ArtifactDataModel).reduce((acc, artifactType) => {
  acc[artifactType] = {};
  return acc;
}, {}));

const artifactsOrder = reactive(Object.keys(ArtifactDataModel).reduce((acc, artifactType) => {
  acc[artifactType] = [];
  return acc;
}, {}));

function resetComputedProperties(artifactType) {
  artifactsOrder[artifactType] = sortItemsByCreatedAt(artifacts[artifactType]);
}

function getNewArtifactId(artifactType) {
  const existingNewItem = Object.values(artifacts[artifactType] || {}).find(item => !item.created_at);
  return existingNewItem?.id || uuidv4();
}

function setArtifactData(artifactType, artifactId, artifactData) {
  if (artifactId) {
    artifacts[artifactType][artifactId] = {
      ...artifactData,
    };
    resetComputedProperties(artifactType);
  }
}

function setArtifacts(artifactType, newArtifacts) {
  if (!Array.isArray(newArtifacts)) {
    return;
  }
  (newArtifacts || []).forEach(itemData => {
    setArtifactData(artifactType, itemData.id, itemData);
  });
}

function cleanArtifactsByType(artifactType) {
  artifacts[artifactType] = {};
  editedArtifacts[artifactType] = {};
}

function cleanApplicationArtifacts() {
  ApplicationArtifactTypes.forEach(artifactType => {
    cleanArtifactsByType(artifactType);
  });
}

async function listGenericEntities(artifactType, key, value) {
  return fetchGlueCharmMethod('list-generic-entities', `list-${artifactType}`, {
    entity_name: artifactType,
    key: key,
    value: value,
  }, 'gluex');
}

async function saveGenericEntity(entityName, entity) {
  if (!entity.summary) {
    entity.summary = entity.description;
  }

  return fetchGlueCharmMethod('save-generic-entity', `save-${entity.id}`, {
    entity_name: entityName,
    entity,
  }, 'gluex');
}

async function getGenericEntity(entityName, entityId) {
  return fetchGlueCharmMethod('get-generic-entity', `get-${entityId}`, {
    entity_name: entityName,
    id: entityId,
  }, 'gluex');
}

async function deleteGenericEntity(entityName, entityId) {
  return fetchGlueCharmMethod('delete-generic-entity', `delete-${entityId}`, {
    entity_name: entityName,
    id: entityId,
  }, 'gluex');
}

async function getMetaFile(entityName, entityId) {
  if (!metaDataFiles[entityName]) {
    metaDataFiles[entityName] = {};
  }

  if (metaDataFiles[entityName]?.[entityId]) {
    return metaDataFiles[entityName][entityId];
  }

  const response = await fetchGlueCharmMethod('get-meta-file', `getfile-${entityId}`, {
    entity_name: entityName,
    id: entityId,
  }, 'gluex');
  
  if (response?.data?.url) {
    metaDataFiles[entityName][entityId] = response?.data?.url;
  }

  return response?.success && response?.data?.url;
}

async function saveMetaFile(entityName, entity) {
  const response = await fetchGlueCharmMethod('save-meta-file', `savefile-${entity.id}`, {
    entity_name: entityName,
    entity,
  }, 'gluex');

  if (response?.success) {
    return getMetaFile(entityName, entity.id);
  }

  return response?.success;
}

async function listApplicationsByWorkspace(workspaceId) {
  const cacheArtifacts = await getPersistentData(workspaceId);

  cleanArtifactsByType(ArtifactTypes.Application);

  if (cacheArtifacts) {
    setArtifacts(ArtifactTypes.Application, cacheArtifacts.application);
  }

  const response = await listGenericEntities(ArtifactTypes.Application, 'ownership_workspace_id', workspaceId);
  console.log('🚀 ~ listApplicationsByWorkspace ~ response:', response);
  
  if (response?.data?.entities) {
    cleanArtifactsByType(ArtifactTypes.Application);
    setArtifacts(ArtifactTypes.Application, response?.data?.entities);
    updateWorkspacePersistentData(workspaceId);
  }

  return response?.success;
}

function updateWorkspacePersistentData(workspaceId) {
  const newPersistentData = WorkspaceArtifactTypes.reduce((acc, artifactType) => {
    acc[artifactType] = Object.values(artifacts[artifactType]);
    return acc;
  }, {});
  savePersistentData(workspaceId, newPersistentData);
}

function updateApplicationPersistentData(appId) {
  const newPersistentData = ApplicationArtifactTypes.reduce((acc, artifactType) => {
    acc[artifactType] = Object.values(artifacts[artifactType]);
    return acc;
  }, {});
  savePersistentData(appId, newPersistentData);
}

async function listArtifactsByApplication(artifactType, appId) {
  const response = await listGenericEntities(artifactType, 'ownership_application_id', appId);
  
  cleanArtifactsByType(artifactType);

  if (response?.data?.entities) {
    setArtifacts(artifactType, response?.data?.entities);
  }

  return response?.success;
}

async function getAllApplicationArtifacts(appId) {
  const cacheArtifacts = await getPersistentData(appId);

  if (cacheArtifacts) {
    Object.keys(cacheArtifacts).forEach(artifactType => {
      cleanArtifactsByType(artifactType);
      setArtifacts(artifactType, cacheArtifacts[artifactType] || []);
    });
  }

  const response = await fetchGlueCharmMethod('mass-get-generic-entities', `getAllApplicationArtifacts-${appId}`, {
    ownership_application_id: appId,
    generation_version: 'v0',
  }, 'gluex');

  console.log('🚀 ~ getAllApplicationArtifacts ~ response:', response);
  if (response?.success) {
    const responseArtifacts = response?.data?.entities;
    
    Object.keys(responseArtifacts).forEach(artifactType => {
      Object.keys(editedArtifacts[artifactType] || {}).forEach(artifactId => {
        const responseArtifact = responseArtifacts[artifactType].find(item => item.id === artifactId);
        const editedArtifact = editedArtifacts[artifactType][artifactId];
        if (responseArtifact?.version === editedArtifact?.version) {
          responseArtifacts[artifactType] = responseArtifacts[artifactType].map(item => item.id === artifactId ? artifacts[artifactType][artifactId] : item);
          saveLocalArtifactChanges(artifactType, artifactId);
        }
      });
    });

    Object.keys(responseArtifacts).forEach(artifactType => {
      cleanArtifactsByType(artifactType);
      setArtifacts(artifactType, responseArtifacts[artifactType]);
    });

    updateApplicationPersistentData(appId);
  }
}

function updateLocalArtifact(artifactType, artifactId, updatedArtifactData) {
  const oldArtifactData = artifacts[artifactType][artifactId];
  const defaultValues = { id: artifactId };

  if (ApplicationArtifactTypes.includes(artifactType)) {
    defaultValues.ownership_application_id = activeApplicationId.value;
  }

  const newArtifactData = {
    ...defaultValues,
    ...oldArtifactData,
    ...updatedArtifactData,
  };

  if (deepEqualData(oldArtifactData, newArtifactData)) {
    return;
  }

  if (!editedArtifacts[artifactType][artifactId]) {
    editedArtifacts[artifactType][artifactId] = {
      ...oldArtifactData,
    };
  }
  
  setArtifactData(artifactType, artifactId, newArtifactData);

  if (deepEqualData(artifacts[artifactType][artifactId], editedArtifacts[artifactType][artifactId])) {
    delete editedArtifacts[artifactType][artifactId];
  }
}

function cancelLocalArtifactChanges(artifactType, artifactId) {
  if (editedArtifacts[artifactType][artifactId]) {
    if (isEmptyData(editedArtifacts[artifactType][artifactId])) {
      if (newArtifactActions[artifactId]?.onCancel) {
        newArtifactActions[artifactId]?.onCancel();
        newArtifactActions[artifactId] = null;
        delete newArtifactActions[artifactId];
      }
      delete artifacts[artifactType][artifactId];
    } else {
      setArtifactData(artifactType, artifactId, editedArtifacts[artifactType][artifactId]);
    }
    delete editedArtifacts[artifactType][artifactId];
  }
}

function validateArtifact(artifactType, artifactId) {
  const artifactData = artifacts[artifactType][artifactId];
  const artifactFields = Object.keys(ArtifactDataModel[artifactType].fields || {});

  return artifactFields.every(field => {
    const isRequired = !DisabledArtifactFields.includes(field) && ArtifactDataModel[artifactType].fields[field]._is_required;
    if (isRequired) {
      if (!artifactData[field]) {
        console.log(`NOT VALID: field ${field} is required in type ${artifactType}`);
      }
      return !!artifactData[field];
    }
    return true;
  });
}

async function saveLocalArtifactChanges(artifactType, artifactId, getOnCreate = true) {
  if (!editedArtifacts[artifactType][artifactId]) {
    return true;
  }

  if (!validateArtifact(artifactType, artifactId)) {
    return false;
  }

  if (ApplicationArtifactTypes.includes(artifactType)) {
    const appId = artifacts[artifactType][artifactId].ownership_application_id;
    if (fetchingActions.value.has(`getAllApplicationArtifacts-${appId}`)) {
      return true;
    }
  }

  const artifactData = artifacts[artifactType][artifactId];
  delete artifactData.version;

  delete editedArtifacts[artifactType][artifactId];

  const response = await saveGenericEntity(artifactType, artifactData);
  
  if (response?.success) {
    if (!artifactData.created_at) {
      if (newArtifactActions[artifactId]?.onCreate) {
        newArtifactActions[artifactId]?.onCreate();
        newArtifactActions[artifactId] = null;
        delete newArtifactActions[artifactId];
      }
      if (getOnCreate) {
        await getArtifactById(artifactType, artifactId);
      }
    }
  }

  if (WorkspaceArtifactTypes.includes(artifactType)) {
    updateWorkspacePersistentData(artifactData.ownership_workspace_id);
  }

  if (ApplicationArtifactTypes.includes(artifactType)) {
    updateApplicationPersistentData(artifactData.ownership_application_id);
  }

  return response?.success;
}

async function deleteArtifact(artifactType, artifactId) {
  const firstArtifactData = artifacts[artifactType][artifactId];
  if (!firstArtifactData?.created_at) {
    const artifactsClone = { ...artifacts[artifactType] };
    delete artifactsClone[artifactId];

    setArtifacts(artifactType, Object.values(artifactsClone));

    delete editedArtifacts[artifactType][artifactId];

    return true;
  }
  
  const response = await deleteGenericEntity(artifactType, artifactId);

  if (response?.success) {
    deleteArtifactFromReferences(artifactType, artifactId);
    deleteChildArtifacts(artifactType, artifactId);

    delete artifacts[artifactType][artifactId];
    delete editedArtifacts[artifactType][artifactId];

    if (WorkspaceArtifactTypes.includes(artifactType)) {
      updateWorkspacePersistentData(firstArtifactData.ownership_workspace_id);
    }

    if (ApplicationArtifactTypes.includes(artifactType)) {
      updateApplicationPersistentData(firstArtifactData.ownership_application_id);
    }
  }

  return response?.success;
}

function deleteChildArtifacts(artifactType, artifactId) {
  Object.entries(ArtifactDataModel[artifactType].fields || {}).forEach(([field, fieldConfig]) => {
    if (fieldConfig.base === ArtifactFieldBases.Artifact && !fieldConfig._is_reference) {
      const fieldValue = artifacts[artifactType][artifactId][field];
      (fieldConfig._is_array ? fieldValue || [] : [fieldValue]).forEach(childArtifactId => {
        deleteArtifact(fieldConfig.type, childArtifactId);
      });
    }
  });
}

function deleteArtifactFromReferences(artifactType, artifactId) {
  Object.entries(ArtifactDataModel).forEach(([type, config]) => {
    Object.entries(config.fields || {}).forEach(([field, fieldConfig]) => {
      if (fieldConfig.type === artifactType) {
        Object.keys(artifacts[type] || {}).forEach(refArtifactId => {
          const fieldValue = artifacts[type][refArtifactId][field];
          if (Array.isArray(fieldValue) && fieldValue.includes(artifactId)) {
            updateLocalArtifact(type, refArtifactId, {
              [field]: artifacts[type][refArtifactId][field].filter(id => id !== artifactId),
            });
            saveLocalArtifactChanges(type, refArtifactId);
          }
        });
      }
    });
  });
}

async function getArtifactById(artifactType, artifactId) {
  const response = await getGenericEntity(artifactType, artifactId);

  if (response?.data?.entity) {
    setArtifactData(artifactType, artifactId, response?.data?.entity);
  }

  return response?.success;
}

async function batchGetArtifactsByIds(artifactType, artifactIds) {
  const response = await fetchGlueCharmMethod('batch-get-generic-entity', `batchGetArtifactsByIds-${artifactType}`, {
    entity_name: artifactType,
    ids: artifactIds,
    generation_version: 'v0',
  }, 'gluex');
  
  if (response?.success) {
    console.log('🚀 ~ batchGetArtifactsByIds ~ response:', response);
    const firstArtifactData = response?.data?.entities[0];

    setArtifacts(artifactType, response?.data?.entities);

    if (WorkspaceArtifactTypes.includes(artifactType)) {
      updateWorkspacePersistentData(firstArtifactData.ownership_workspace_id);
    }

    if (ApplicationArtifactTypes.includes(artifactType)) {
      updateApplicationPersistentData(firstArtifactData.ownership_application_id);
    }
  }

}

async function batchSaveArtifacts(artifactType, artifacts) {
  console.log(`🚀 ~ batchSaveArtifacts ${artifactType} ~ artifacts:`, artifacts);
  const response = await fetchGlueCharmMethod('batch-save-generic-entity', `batchSaveArtifactsByIds-${artifactType}`, {
    entity_name: artifactType,
    entities: artifacts,
    generation_version: 'v0',
  }, 'gluex');
  
  if (response?.success) {
    setArtifacts(artifactType, artifacts);

    if (WorkspaceArtifactTypes.includes(artifactType)) {
      updateWorkspacePersistentData(artifacts[0].ownership_workspace_id);
    }

    if (ApplicationArtifactTypes.includes(artifactType)) {
      updateApplicationPersistentData(artifacts[0].ownership_application_id);
    }
  }
}

async function getDocumentationBrief(applicationId) {
  const response = await fetchGlueCharmMethod('get-documentation-brief', `get-brief-${applicationId}`, {
    application_id: applicationId,
  }, 'gluex');
  
  console.log('🚀 ~ getDocumentationBrief ~ response:', response);
  if (response?.success) {
    return response.data;
  }

  return response?.success;
}

const PrimitiveTypes = {
  String: 'string',
  Number: 'number',
  Boolean: 'boolean',
};

function getDefaultFieldValue(artifactType, fieldName) {
  const {type, _is_array} = ArtifactDataModel[artifactType].fields[fieldName];
  if (_is_array) {
    return [];
  } else {
    switch (type) {
      case PrimitiveTypes.Number:
        return 0;
      case PrimitiveTypes.Boolean:
        return true;
      default:
      case PrimitiveTypes.String:
        return '';
    }
  }
}

function createNewLocalArtifact(artifactType, artifactData = {}, actions) {
  if (!ArtifactDataModel[artifactType]) {
    console.log(`createNewLocalArtifact error: artifactType ${artifactType} not found in DataModel`);
    return null;
  }

  const artifactFields = Object.keys(ArtifactDataModel[artifactType].fields || {});
  
  artifactData.id = artifactData.id || uuidv4();
  
  if (artifactFields.includes('ownership_application_id')) {
    artifactData.ownership_application_id = artifactData.ownership_application_id || activeApplicationId.value;
  }

  updateLocalArtifact(artifactType, artifactData.id, artifactFields.reduce((acc, field) => {
    acc[field] = artifactData[field] !== null ? artifactData[field] : getDefaultFieldValue(artifactType, field);
    return acc;
  }, {}));

  if (actions) {
    newArtifactActions[artifactData.id] = actions;
  }

  return artifactData.id;
}

export default function useArtifacts() {
  return {
    artifacts: readonly(artifacts),
    editedArtifacts: readonly(editedArtifacts),
    artifactsOrder,
    getArtifactById,
    getNewArtifactId,
    cleanApplicationArtifacts,
    listApplicationsByWorkspace,
    listArtifactsByApplication,
    getAllApplicationArtifacts,
    setArtifacts,
    updateLocalArtifact,
    cancelLocalArtifactChanges,
    saveLocalArtifactChanges,
    deleteArtifact,
    createNewLocalArtifact,
    batchGetArtifactsByIds,
    batchSaveArtifacts,
    getMetaFile,
    validateArtifact,
    saveMetaFile,
    getDocumentationBrief,
  };
}
