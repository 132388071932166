import { ref } from 'vue';

import { FlowNodeTypes } from './flows';

export const AllInited = ref(false);

export const isProd = import.meta.env.PROD;
export const viteEnv = import.meta.env.VITE_ENV;

export const GCMailTypes = {
  Help: 'help',
  Design: 'design',
  Calendly: 'calendly',
  Gift: 'gift',
  Upgrade: 'upgrade',
  Founders: 'founders',
};

export const SharedOrgData = {
  id: 'shared',
  name: 'Apps Shared With Me',
  slug: 'shared-with-me',
};

export const Language = {
  EN: 'en',
  ES: 'es',
};

export const CloudPlatforms = {
  'Amazon AWS': 'amazon_aws',
};

export const CloudPlatformUrls = {
  [CloudPlatforms['Amazon AWS']]: 'https://aws.amazon.com/',
};

export const GitPlatforms = {
  Bitbucket: 'bitbucket',
  GitHub: 'github',
  GitLab: 'gitlab',
};

export const RouteNames = {
  Login: 'login',
  Register: 'register',
  Upgrade: 'upgrade',
  ResetPassword: 'reset_password',
  NoOrganization: 'no_organization',
  Workspace: 'workspace',
  ManageWorkspace: 'manage_workspace',
  NewApplication: 'new_application',
  Application: 'Overview',
  Users: 'Users',
  UseCases: 'Use Cases',
  UserStories: 'User Stories',
  Events: 'Events',
  Services: 'Services',
  DataTypes: 'Data Types',
  Environments: 'Environments',
  Versions: 'Versions',
  Generation: 'Code Generation',
  Deployments: 'Deployments',
  User: 'User',
  CustomerJourney: 'Customer Journey',
  Usecase: 'Use Case',
  UserStory: 'User Story',
  Event: 'Event',
  FrontendService: 'FrontendService',
  BackendService: 'BackendService',
  HumanInteraction: 'Human Interaction',
  HumanInteractionList: 'Human Interaction List',
  Features: 'Features',
  Feature: 'Feature',
  Backlog: 'Backlog',
  Views: 'Views',
  View: 'View',
  Artifact: 'Artifact',
  ArtifactList: 'Artifact List',
};

export const Roles = {
  Developer: 'developer',
  Cpo: 'cpo',
  Cto: 'cto',
  ProductManager: 'product-manager',
  ProductOwner: 'product-owner',
  ProductMember: 'product-member',
  BusinessAnalyst: 'business-analyst',
  Investor: 'investor',
  Other: 'other',
};

export const RolesLiterals = {
  [Roles.Developer]: 'Developer',
  [Roles.Cpo]: 'CPO',
  [Roles.Cto]: 'CTO',
  [Roles.ProductManager]: 'Product Manager',
  [Roles.ProductMember]: 'Product Member',
  [Roles.BusinessAnalyst]: 'Business Analyst',
  [Roles.Investor]: 'Investor',
  [Roles.Other]: 'Other',
};

export const InvitationTypes = {
  RegisterInvitation: 'register-invitation',
  OrganizationInvitation: 'organization-invitation',
  ProjectInvitation: 'project-invitation',
  ProjectRegisterInvitation: 'project-register-invitation',
};

export const ServiceTypes = {
  Frontend: 'frontend',
  Backend: 'backend',
  Core: 'core',
  Infrastructure: 'infrastructure',
  Library: 'library',
};

export const AllowedCreateServiceTypes = [ServiceTypes.Frontend, ServiceTypes.Backend];

export const ServiceNodeTypeServiceType = {
  [FlowNodeTypes.FrontendMethod]: ServiceTypes.Frontend,
};

export const WorkflowEngines = {
  ['Temporal (production ready version)']: 'temporal',
  ['Temporalite (non-production version)']: 'temporalite',
};

export const SkeletonNames = {
  'aws_lambda': 'AWS Lambda (serverless)',
  'aws_ecs': 'AWS ECS',
  'aws_s3': 'AWS S3 Bucket',
};

export const PopupSizes = {
  Auto: 'auto',
  Small: '400px',
  Medium: '540px',
  Large: '720px',
  ExtraLarge: '920px',
  Full: '95%',
};

export const AnalyticEvents = {
  Register: 'Signed In',
  UserStoryCreated: 'Created User Story',
  UseCaseCreated: 'Created Use Case',
};

export const HistoryChangeTypes = {
  Create: 'create',
  Update: 'update',
  Delete: 'delete',
};

export const UpgradeTypes = {
  Usecases: 'usecases',
};

export const SkeletonTypes = {
  Go: 'Microservice Go',
  ['Python 3.11']: 'Microservice Python 3.11',
  ['Python 3.12']: 'Microservice Python 3.12',
};

export const DefaultInsights = [
  'Summary Lorem ipsum dolor sit amet, consectetur adipiscing elit.', 
  'Ut a nisl et arcu elementum blandit non a dolor. Curabitur ullamcorper urna sit amet augue congue lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  'Eu interdum lorem congue. Aenean in lacinia arcu, vel hendrerit ligula.',
  'Link to Report inside the Insight Research tab',
];

export const MoscowPriorities = {
  MustHave: 'must_have',
  ShouldHave: 'should_have',
  CouldHave: 'could_have',
  WontHave: 'wont_have',
};

export const MoscowPriorityLabels = {
  [MoscowPriorities.MustHave]: 'Must Have',
  [MoscowPriorities.ShouldHave]: 'Should Have',
  [MoscowPriorities.CouldHave]: 'Could Have',
  [MoscowPriorities.WontHave]: 'Wont Have',
};

export const MoscowPrioritiesColors = {
  [MoscowPriorities.MustHave]: 'bg-emerald-600',
  [MoscowPriorities.ShouldHave]: 'bg-blue-600',
  [MoscowPriorities.CouldHave]: 'bg-pink-500',
  [MoscowPriorities.WontHave]: 'bg-gray-600',
};

export const MagicToolbarActions = {
  Magic: 'magic',
  CheckSpelling: 'check_spelling',
  SummarizeText: 'summarize_text',
  ExpandText: 'expand_text',
};
