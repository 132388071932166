import { readonly, ref, computed, watch } from 'vue';
import { router, activeWorkspaceSlug } from '@/router_NEW';

import useGlueCharmServices from '@/composables_NEW/useGlueCharmServices';
import useArtifacts from '@/composables_NEW/useArtifacts';

import useFetching from '@/composables_NEW/useFetching';
import useLocalEdition from '@/composables_NEW/useLocalEdition';
import usePersistentData from '@/composables_NEW/usePersistentData';
import useCloudPlatformConfigurations from '@/composables_NEW/useCloudPlatformConfigurations';
import useVcrConfigurations from '@/composables_NEW/useVcrConfigurations';
import useMembers from '@/composables_NEW/useMembers';
import useAnalytics from '@/composables_NEW/useAnalytics';
import useUsers from '@/composables_NEW/useUsers';

import { sortItemsByCreatedAt } from '@/utils/utils';
import { DataAssetTypes } from '@/constants/dataAssets';
import { RouteNames, SharedOrgData } from '@/constants/common';

const { isUserAuthenticated, fetchGlueCharmMethod } = useGlueCharmServices();
const { addFetchingAction, setFetchingActionDone, addFetchingError, fetchingErrors } = useFetching();
const { savePersistentData, getPersistentData} = usePersistentData();
const { cloudPlatformConfigurations, getCloudPlatformConfigurationsList, setCloudPlatformConfigurations } = useCloudPlatformConfigurations();
const { vcrConfigurations, getVcrConfigurationsList, setVcrConfigurations } = useVcrConfigurations();
const { members, invitations, getMembersList, listUnacceptedInvitations, setOrganizationMembers, setOrganizationInvitations } = useMembers();
const { identifyWorkspace } = useAnalytics();
const { activeUser } = useUsers();

const { listApplicationsByWorkspace } = useArtifacts();

const workspaces = ref({});

const workspacesOrder = computed(() => sortItemsByCreatedAt(workspaces.value).reverse());
const activeWorkspace = computed(() => Object.values(workspaces.value).find(orgData => orgData.slug === activeWorkspaceSlug.value));
const persistentDataKey = computed(() => `workspace-contents-${activeWorkspace.value.id}`);

useLocalEdition({
  items: {
    type: DataAssetTypes.Organizations,
    ref: workspaces,
  },
  callbacks: {
    update: async(updatedAppId) => {
      updatePersistentData(updatedAppId);
    },
  },
});

function updatePersistentData(orgId) {
  const newPersistedData = {
    cloudConfigs: Object.values(cloudPlatformConfigurations.value).filter(config => config.created_at && config.organization_id === orgId),
    vcrConfigs: Object.values(vcrConfigurations.value).filter(config => config.created_at && config.organization_id === orgId),
    orgMembers: members.value,
    orgInvitations: invitations.value,
  };

  savePersistentData(persistentDataKey.value, newPersistedData);
}

async function getFullWorkspaceContent(orgId) {
  const isShared = orgId === 'shared';

  addFetchingAction(`getFullWorkspaceContent-${orgId}`);

  const persistedData = await getPersistentData(persistentDataKey.value);
  
  if (persistedData) {
    setCloudPlatformConfigurations(persistedData.cloudConfigs);
    setVcrConfigurations(persistedData.vcrConfigs);
    setOrganizationMembers(orgId, persistedData.orgMembers);
    setOrganizationInvitations(orgId, persistedData.orgInvitations);
  }

  const appsLoaded = await listApplicationsByWorkspace(orgId);

  const orgContentLoaded = isShared || await Promise.all([
    getMembersList(orgId),
    listUnacceptedInvitations(orgId),
    getCloudPlatformConfigurationsList(orgId),
    getVcrConfigurationsList(orgId),
  ]);

  const allLoaded = appsLoaded && (orgContentLoaded || ![...orgContentLoaded].includes(false));

  if (allLoaded) {
    updatePersistentData(orgId);
    setFetchingActionDone(`getFullWorkspaceContent-${orgId}`);
    
    if (workspaces.value[orgId]) {
      workspaces.value[orgId].loaded = true;
    }

    return true;
  }

  addFetchingError(`getFullWorkspaceContent-${orgId}`,Object.values(fetchingErrors.value)[0]);
  return false;
}

async function getWorkspacesList() {
  const response = await fetchGlueCharmMethod('organization-manager/list', 'getWorkspacesList');

  if (response.organizations?.length) {
    response.organizations.push(SharedOrgData);

    response.organizations.forEach(orgData => {
      if (orgData.name === 'Sandbox') {
        orgData.name = 'My workspace';
        orgData.slug = 'my-workspace';
      }
      workspaces.value[orgData.id] = {
        ...orgData,
      };
    });
  } else {
    return;
  }
}

watch(isUserAuthenticated, async () => {
  if (!isUserAuthenticated.value) {
    workspaces.value = {};
  } else {
    await getWorkspacesList();
    
    if (!activeWorkspace.value) {
      if (!activeUser.value) {
        await new Promise((resolve) => {
          const stopWatch = watch(activeUser, () => {
            stopWatch();
            resolve();
          });
        });
      }
    
      const preferedWorkspace = workspacesOrder.value.find(workspaceId => activeUser.value.memberships?.[workspaceId]?.isOwner) || workspacesOrder.value[0];

      if (router.currentRoute.value.name !== RouteNames.Upgrade) {
        router.push({
          name: RouteNames.Workspace,
          params: {
            org: workspaces.value[preferedWorkspace]?.slug,
          },
        });
      }
    }
  }
});

watch(activeWorkspace, (newVal, oldVal) => {
  if (activeWorkspace.value) {
    identifyWorkspace({ ...activeWorkspace.value });
    if (newVal !== oldVal) {
      getFullWorkspaceContent(newVal.id);
    }
  }
}, {immediate: true});

export default function useWorkspaces () {
  return {
    workspaces: readonly(workspaces),
    workspacesOrder: readonly(workspacesOrder),
    activeWorkspace,
    getFullWorkspaceContent,
    getWorkspacesList,
  };
}
