import { ArtifactTypes } from './artifacts';
import { RouteNames } from './common';
import { DataAssetTypes } from './dataAssets';
import { FormColors } from './forms';

export const GenerationStates = {
  MissingConfig: 'Missing configuration',
  Allowed: 'Ready To Generate',
  Generating: 'generating',
  Generated: 'generated',
  Failed: 'failed',
};

export const GenerationStateIcons = {
  [GenerationStates.Allowed]: 'check',
  [GenerationStates.Generating]: 'loading',
  [GenerationStates.Generated]: 'check',
  [GenerationStates.Failed]: 'warning',
};

export const GenerationStateClasses = {
  [GenerationStates.MissingConfig]: 'text-red-800',
  [GenerationStates.Allowed]: 'text-green-800',
  [GenerationStates.Generating]: 'text-gray-400',
  [GenerationStates.Generated]: 'text-green-800',
  [GenerationStates.Failed]: 'text-red-500 stroke-red-500',
};

export const DeployStates = {
  Deploying: 'deploying',
  Deployed: 'deployed',
  Failed: 'failed',
};

export const DeployStateIcons = {
  [DeployStates.Deploying]: 'deploying',
  [DeployStates.Deployed]: 'deployed',
  [DeployStates.Failed]: 'warning',
};

export const DeployStateClasses = {
  [DeployStates.Deploying]: '',
  [DeployStates.Deployed]: '[&_svg]:fill-green-600',
  [DeployStates.Failed]: '[&_svg]:stroke-red-800',
};

export const UsecaseNameFormats = {
  PersonaStory: 'persona story format',
  UserStory: 'user story format',
};

export const BacklogStates = {
  NotStarted: 'not_started',
  InProgress: 'in_progress',
  Completed: 'completed',
};

export const BacklogStateLiterals = {
  [BacklogStates.NotStarted]: 'Not Started',
  [BacklogStates.InProgress]: 'In Progress',
  [BacklogStates.Completed]: 'Completed',
};

export const BacklogStateColors = {
  [BacklogStates.NotStarted]: FormColors.Gray,
  [BacklogStates.InProgress]: FormColors.Yellow,
  [BacklogStates.Completed]: FormColors.Green,
};

export const OnBoardingTypes = {
  NewApplication: 'new-application',
  NewFeature: 'new-feature',
};

export const OnBoardingTypePatterns = {
  [OnBoardingTypes.NewApplication]: 'explore',
  [OnBoardingTypes.NewFeature]: 'application',
};

export const OnBoardingTypePatternSizes = {
  [OnBoardingTypes.NewApplication]: 'auto',
  [OnBoardingTypes.NewFeature]: '33.5px 50px',
};

export const OnBoardingTypeIcons = {
  [OnBoardingTypes.NewApplication]: 'binoculars',
  [OnBoardingTypes.NewFeature]: 'devices',
};

export const OnBoardingTypeColors = {
  [OnBoardingTypes.NewApplication]: 'bg-cyan-500',
  [OnBoardingTypes.NewFeature]: 'bg-indigo-700',
};

export const OnBoardingContents = {
  [OnBoardingTypes.NewApplication]: {
    breadcrumbLabel: 'New Application',
    title: 'Explore an idea for a new product',
    features: [
      '(web or app)',
    ],
    questions: [
      {
        label: 'What\'s your idea and what problem does it solve?',
        placeholder: 'Describe the main issue or inconvenience you are trying to solve',
      },
      {
        label: 'What\'s missing or not good enough in the current solutions out there?',
        placeholder: 'Describe what\'s wrong with the existing ways and how your idea can improve things.',
      },
      {
        label: 'Who will benefit the most from using your idea and how will they use it?',
        placeholder: 'Describe your target audience',
      },
    ],
    buttonLabel: 'Explore',
  },
  [OnBoardingTypes.NewFeature]: {
    breadcrumbLabel: 'New Feature',
    title: 'Scope a new feature for an existing product',
    features: [],
    questions: [
      {
        label: 'What product do you currently offer, and what does it do?',
        placeholder: 'Describe your existing product and its key functionalities.',
      },
      {
        label: 'What new feature are you considering, and what problems will it solve?',
        placeholder: 'Describe the feature and why it will be useful.',
      },
      {
        label: 'Who will benefit the most from this new feature, and how will they use it?',
        placeholder: 'Describe who will use the new feature and how.',
      },      
    ],
    buttonLabel: 'Start',
  },
};

export const ApplicationAssetOrder = [
  DataAssetTypes.Actors,
  DataAssetTypes.Usecases,
  DataAssetTypes.UserStories,
  DataAssetTypes.Services,
  DataAssetTypes.Events,
];

export const ApplicationAssetConfigs = {
  [ArtifactTypes.Actor]: {
    name: 'Users',
    icon: 'user',
    color: 'bg-amber-200',
    routeName: RouteNames.Users,
  },
  [ArtifactTypes.Feature]: {
    name: 'Features',
    icon: 'list-checks',
    color: 'bg-emerald-200',
    routeName: RouteNames.Features,
  },
  [ArtifactTypes.UserStory]: {
    name: 'User Stories',
    icon: 'identification-card',
    color: 'bg-violet-200',
    routeName: RouteNames.UserStories,
  },
};

export const PermissionTypes = {
  ReadOnly: 'read_only',
  Full: 'full',
};

export const PermissionTypeLiterals = {
  [PermissionTypes.ReadOnly]: 'Read Only',
  [PermissionTypes.Full]: 'Full Access',
};
