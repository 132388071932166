<script setup>
import { ref, nextTick, computed, onMounted } from 'vue';
import { onClickOutside } from '@vueuse/core';

import useResizeObserver from '@/composables_NEW/useResizeObserver';

import GlueButton from '@/components_NEW/formFields/GlueButton.vue';
import ArrowDown from '@/svg/arrow_down.svg';

import { FormStyles, FormColors, FormBorderStyles, ButtonSizes, MenuButtonAligns } from '@/constants/forms';
import { appendPx } from '@/utils/utils';

const emit = defineEmits(['clickMenuItem', 'openMenu']);

const props = defineProps({
  icon: String,
  items: Object,
  fetchingAction: String,
  label: String,
  tooltip: String,
  full: Boolean,
  disabled: Boolean,
  hideLabel: {
    type: Boolean,
    default: false,
  },
  disableOutsideClose: {
    type: Boolean,
    default: false,
  },
  allowSingleItem: {
    type: Boolean,
    default: false,
  },
  align: {
    type: String,
    default: MenuButtonAligns.Left,
  },
  hideArrow: {
    type: Boolean,
    default: false,
  },
  borderStyle: {
    type: String,
    default: FormBorderStyles.Straight,
  },
  style: {
    type: String,
    default: FormStyles.Naked,
  },
  size: {
    type: String,
    default: ButtonSizes.Default,
  },
  color: {
    type: String,
    default: FormColors.Indigo,
  },
});

const submenuHidden = ref(true);
const rootDom = ref();
const submenuDom = ref();
const rootDomRect = ref();
const submenuHeight = ref();
const submenuWidth = ref();

useResizeObserver(submenuDom, () => {
  submenuHeight.value = submenuDom.value?.offsetHeight || 0;
  submenuWidth.value = submenuDom.value?.offsetWidth;
});

const isSingleAction = computed(() => !props.allowSingleItem && props.items && Object.keys(props.items).length === 1);
const showArrow = computed(() => !props.hideArrow && !isSingleAction.value);
const computedLabel = computed(() => props.hideLabel ? '' : isSingleAction.value ? props.tooltip ? '' : Object.keys(props.items)[0] : props.label);

const submenuStyle = computed(() => {
  if (!submenuWidth.value) {
    return {};
  }
  const {
    left,
    top,
    width,
    height,
  } = rootDomRect.value;

  const showOnTop = top + height + submenuHeight.value > window.innerHeight - 20;
  const alignRight = left + submenuWidth.value > window.innerWidth - 20;

  const position = {
    top: appendPx(showOnTop ? top - submenuHeight.value : top + height),
    left: appendPx(left + (alignRight ? -submenuWidth.value + width : 0)),
    right: 'auto',
    minWidth: appendPx(Math.max(width,submenuWidth.value)),
  };

  return position;
});

onClickOutside(rootDom, () => {
  if (!props.disableOutsideClose) {
    submenuHidden.value = true;
  }
});

function openMenu() {
  setRootDomRect();
  submenuHidden.value = false;
  
  emit('openMenu');
}

function closeMenu() {
  setRootDomRect();
  submenuHidden.value = true;
}

function openCloseMenu() {
  if (submenuHidden.value) {
    openMenu();
  } else {
    closeMenu();
  }
}

function onClickMainButton() {
  if (isSingleAction.value) {
    emit('clickMenuItem', Object.values(props.items)[0]);
  } else {
    openCloseMenu();
  }
}

function onClickMenuItem(action) {
  submenuHidden.value = true;
  nextTick(() => {
    emit('clickMenuItem', action);
  });
}

function setRootDomRect() {
  if (rootDom.value) {
    rootDomRect.value = rootDom.value.getBoundingClientRect();
  }
}

onMounted(() => {
  window.addEventListener('resize', () => {
    setRootDomRect();
  });

  document.getElementById('container').addEventListener('scroll', () => {
    closeMenu();
  });
});

defineExpose({
  openMenu,
  closeMenu,
});
</script>

<template>
  <div ref="rootDom">
    <GlueButton
      class="flex items-center"
      :fetching-action="fetchingAction"
      :border-style="borderStyle"
      :disabled="disabled"
      :style="style"
      :size="size"
      :color="disabled ? FormColors.Disabled : color"
      :label="computedLabel"
      :tooltip="tooltip"
      :full="full"
      :icon="icon"
      @clickButton="onClickMainButton"
    >
      <slot />
      <ArrowDown
        v-if="showArrow"
        class="ml-auto transition-transform w-3"
        :class="[
          style === FormStyles.Solid ? '[&>*]:stroke-white' : '',
          !submenuHidden ? 'rotate-180' : 'rotate-0',
          size === ButtonSizes.Mini && 'h-5 w-5 p-0.5',
          size === ButtonSizes.Default && 'w-8 h-8 p-1',
          size === ButtonSizes.Big && 'h-8',
        ]"
      />
    </GlueButton>
    <Teleport to="#gc-teleports">
      <transition name="fade-submenu">
        <div
          v-if="!submenuHidden"
          ref="submenuDom"
          :style="submenuStyle"
          class="
            absolute flex flex-col text-sm z-50 bg-white pointer-events-auto
            text-gray-800 items-center justify-center shadow-md py-3
          "
        >
          <div
            class="cursor-pointer hover:bg-gray-50 w-full p-2"
            v-for="(action, label, index) in items"
            v-bind:key="index"
            @mousedown="(ev) => {
              onClickMenuItem(action);
            }"
          >
            {{ label }}
          </div>
        </div>
      </transition>
    </Teleport>
  </div>
</template>

<style lang="postcss">
  @import "@/styles/fade.css";
  @import "@/styles/fade-submenu.css";
</style>
