<script setup>
import useLanguages from '@/composables_NEW/useLanguages';

import Icon from './Icon.vue';
import GlueButton from '@/components_NEW/formFields/GlueButton.vue';

import { FormStyles } from '@/constants/forms';

const emit = defineEmits(['clickCreate', 'update:modelValue']);

defineProps({
  modelValue: Boolean,
  icon: String,
  title: String,
  description: Array,
  useEditDescription: Boolean,
});

const { t } = useLanguages();
</script>

<template>
	<div class="flex flex-col items-center justify-center text-sm">
		<Icon class="h-11 p-2 bg-indigo-50 rounded-full" :icon="icon" />
		<div class="text-lg font-medium mt-2 mb-4">{{ title }}</div>
		<div v-for="(text, index) in description" :key="index" v-html="text"/>
		<div v-if="useEditDescription">
			<div v-if="!modelValue">
        <span>{{ t('Edit below or') }}</span>
        <span class="text-indigo-700 font-semibold hover:bg-indigo-100 px-1 cursor-pointer" 
          @click="() => {
						emit('update:modelValue', true);
          }"
        >{{ t('search') }}</span>
        <span>{{ t('in existing library') }}</span>
      </div>
      <div v-else class="flex flex-col items-center">
        <span>{{ t('Select existent') }} {{ title }}</span>
        <GlueButton
          class="mb-8"
          :label="t('Or create a new one')"
          :style="FormStyles.Naked"
          @click-button="() => {
            emit('clickCreate');
          }"
        />
      </div>
		</div>
		<slot />
	</div>
</template>
