<script setup>
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';

import { v4 as uuidv4 } from 'uuid';

import useLanguages from '@/composables_NEW/useLanguages';
import useArtifacts from '@/composables_NEW/useArtifacts';
import useUsers from '@/composables_NEW/useUsers';
import useMembers from '@/composables_NEW/useMembers';
import useWorkspaces from '@/composables_NEW/useWorkspaces';

import GlueButton from '@/components_NEW/formFields/GlueButton.vue';
import TextEditable from '@/components_NEW/formFields/TextEditable.vue';

import { FormColors, FormStyles } from '@/constants/forms';
import { ArtifactTypes } from '@/constants/artifacts';

const { t } = useLanguages();
const route = useRoute();

const { artifacts, updateLocalArtifact, saveLocalArtifactChanges } = useArtifacts();
const { activeUser } = useUsers();
const { activeWorkspace } = useWorkspaces();
const { members } = useMembers();

const newNoteModel = ref();

const activeScope = computed(() => route.path.replace(route.params?.tab, ''));

const notesList = computed(() => {
  const filteredList = Object.values(artifacts[ArtifactTypes.Note])
    .filter(note => note.artifact_scope === activeScope.value)
    .map(note => ({
      ...note,
      parsedDate: new Date(note.created_date).toLocaleDateString(undefined, {
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      }),
      userName: (members.value || []).find(member => member.user_id === note.by_user)?.name,
    }))
    .sort((a, b) => a.created_date - b.created_date);

  return filteredList;
});

function onClickSubmit() {
  if (newNoteModel.value) {
    const newId = uuidv4();
    updateLocalArtifact(ArtifactTypes.Note, newId, {
      id: newId,
      created_date: Date.now(),
      artifact_scope: activeScope.value,
      by_user: activeUser.value.id,
      content: newNoteModel.value,
    });
    saveLocalArtifactChanges(ArtifactTypes.Note, newId);
    newNoteModel.value = '';
  }
}
</script>

<template>
  <div class="w-full pt-24 flex flex-col h-full">
    <div class="flex-grow flex flex-col justify-end gap-2 mt-4 p-4">
      <div
        class="flex flex-col gap-2 bg-slate-50 p-4"
        v-for="{id, content, userName, parsedDate} in notesList"
        :key="id"
      >
        <div class="text-xs text-slate-500">{{ parsedDate }} - <span class="font-semibold">{{ userName }}</span> {{ t('wrote') }}:</div>
        <div class="text-sm">{{ content }}</div>
      </div>
    </div>
    <div class="flex flex-col py-4 gap-4 items-end border-t-2 p-4">
      <TextEditable
        class="bg-slate-50 p-4 h-28 w-full"
        ref="newNoteEditorRef"
        v-model="newNoteModel"
        placeholder="Enter Text"
      />
      <GlueButton
        :label="t('Submit')"
        :color="FormColors.Cyan"
        :style="FormStyles.Outline"
        @click-button="onClickSubmit"
      />
    </div>
  </div>
</template>
