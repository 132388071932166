
<script setup>
import useLanguages from '@/composables_NEW/useLanguages';

import MenuBarItem from './MenuBarItem.vue';
import MenuBarMenuItem from './MenuBarMenuItem.vue';

defineProps({
  menuItems: Array,
  isClosed: Boolean,
});

const { t } = useLanguages();
</script>

<template>
  <div class="flex flex-col gap-4 justify-end">
    <div
      v-for="{name, items} in menuItems"
      :key="name"
    >
      <div
        class="w-full text-xs p-1 pl-3 whitespace-nowrap text-orange-500 font-semibold transition-opacity"
        :class="[
          isClosed ? 'opacity-0' : 'opacity-100',
        ]"
      >
        {{ t(name) }}
      </div>
      <div class="px-2">
        <component
          :is="menuItem.options ? MenuBarMenuItem : MenuBarItem"
          v-for="(menuItem, index) in items"
          :key="index"
          v-bind="menuItem"
          :is-closed="isClosed"
        />
      </div>
    </div>
  </div>
</template>
