<script setup>
import { ref, computed, watch, nextTick } from 'vue';

import { onClickOutside } from '@vueuse/core';

import useResizeObserver from '@/composables_NEW/useResizeObserver';

import Tooltip from '@/components_NEW/formFields/Tooltip.vue';
import AgentButton from '@/components_NEW/agent/AgentButton.vue';
import GlueLabel from '@/components_NEW/formFields/GlueLabel.vue';

import { InputCases } from '@/constants/forms';
import { appendPx, getNewTestId, toCamelCase, toKebabCase, toSnakeCase, toUpperCase } from '@/utils/utils';

const emit = defineEmits(['update:modelValue', 'onSelectPromptValue', 'focus', 'blur', 'resize']);

const forceCaseFunction = {
  [InputCases.SnakeCase]: toSnakeCase,
  [InputCases.CamelCase]: toCamelCase,
  [InputCases.KebabCase]: toKebabCase,
  [InputCases.Uppercase]: toUpperCase,
};

const props = defineProps({
  id: String,
  modelValue: String,
  label: String,
  name: String,
  placeholder: String,
  errorMessage: String,
  required: Boolean,
  disabled: Boolean,
  forceCase: String,
  disableDefaultMagicActions: Boolean,
  avoidNewLines: Boolean,
  disableClickOutside: Boolean,
  magicActions: {
    type: Array,
    default: () => [],
  },
  maxChars: {
    type: Number,
    default: 5000,
  },
});

const rootDom = ref();
const errorTooltipRef = ref();
const isEditing = ref();
const inputDom = ref();
const inputModel = ref();
const agentButtonRef = ref();

const dataTest = computed(() => getNewTestId(props.id || (`${props.label}${props.label ? '-' : ''}text`)));
const value = computed(() => inputModel.value);
const isShowingError = computed(() => errorTooltipRef.value?.showing);

useResizeObserver(rootDom, () => {
  fitTextArea();
});

function onKeyDown(ev) {
  if (ev.key === 'Enter') {
    if (props.avoidNewLines || !inputModel.value.length) {
      ev.preventDefault();
      return false;
    }
  }
}

function fitTextArea() {
  nextTick(() => {
    if (inputDom.value) {
      inputDom.value.style.height = 'auto';

      nextTick(() => {
        inputDom.value.style.height = appendPx(inputDom.value.scrollHeight);
      });
    }
  });
  emit('resize');
}

function onAiResponse({parsedResponse = ''}) {
  if (!parsedResponse) {
    //TODO: repeat the request
    return;
  }
  if (inputModel.value === parsedResponse) {
    agentButtonRef.value?.showMessage('Seems like the text is already correct. I\'ve nothing to change.');
    return;
  }
  inputModel.value = parsedResponse;
  onEditText();
}

function onEditText(ev = {}) {
  if (!isEditing.value && ev?.inputType === 'historyUndo') {
    inputModel.value = props.modelValue;
    return false;
  }
  if (props.maxChars) {
    inputModel.value = (inputModel.value || '').substring(0, props.maxChars);
  }
  if (props.forceCase) {
    inputModel.value = forceCaseFunction[props.forceCase](inputModel.value);
  }
  
  emit('update:modelValue', inputModel.value || '');
  fitTextArea();
}

function hideError() {
  if (errorTooltipRef.value) {
    errorTooltipRef.value.hide();
  }
}

function showError(message) {
  errorTooltipRef.value.show(message || props.errorMessage, {
    top: props.label ? 15 : 0,
    left: inputDom.value?.offsetWidth / 2,
  });
}

function validate() {
  const isValid = !props.required || inputModel.value?.length;

  if (!isValid && props.errorMessage) {
    showError();
  }

  return isValid;
}

function clear() {
  inputModel.value = '';
}

function onFocusInput() {
  hideError();
  isEditing.value = true;
  emit('focus');
}

function focus(selectionStart, selectionEnd) {
  if (inputDom.value) {
    inputDom.value.focus();
    nextTick(() => {
      inputDom.value.setSelectionRange(selectionStart || inputModel.value?.length, selectionEnd || inputModel.value?.length);
    });
  }
}

function blur() {
  isEditing.value = false;
  emit('blur', inputModel.value);
}

onClickOutside(rootDom, () => {
  if (isEditing.value && !props.disableClickOutside) {
    blur();
  }
});

watch(() => props.modelValue, () => {
  inputModel.value = props.modelValue;
}, {immediate: true});

watch(inputModel, () => {
  fitTextArea();
}, {immediate: true});

defineExpose({
  value,
  isEditing,
  inputDom,
  clear,
  validate,
  showError,
  focus,
  blur,
});
</script>

<template>
  <div
    class="relative flex flex-col text-inherit"
    ref="rootDom"
  >
    <Tooltip
      ref="errorTooltipRef"
      :auto-hide="3000"
      :close-button="false"
      :message="errorMessage"
    />
    <GlueLabel v-if="label" :label="label" />
    <textarea
      data-hj-allow
      spellcheck="false"
      data-gramm_editor="false"
      ref="inputDom"
      rows="1"
      class="text-inherit w-full resize-none rounded-none shadow-none transition-colors bg-transparent overflow-hidden overflow-y-auto"
      :data-test="dataTest"
      :disabled="disabled"
      :class="[
        label ? 'mt-0.5' : 'mt-0',
        isEditing ? 'outline-dashed outline-offset-2 outline-2 outline-cyan-400' : 'outline-none',
        isShowingError ? 'outline-dashed outline-offset-2 outline-2 outline-red-400' : 'outline-none',
      ]"
      v-model="inputModel"
      :placeholder="placeholder"
      @input="onEditText"
      @keydown="onKeyDown"
      @focus="onFocusInput"
      @blur="blur"
    />
    <AgentButton
      v-if="!disableDefaultMagicActions"
      ref="agentButtonRef"
      class="absolute -right-6 z-20"
      :showing="isEditing"
      :class="[
        label ? '-top-8' : '-top-14',
      ]"
      :custom-magic-actions="magicActions"
      :model-value="inputModel"
      :limit-chars="maxChars"
      @aiResponse="onAiResponse"
    />
  </div>
</template>

<style lang="postcss">
  textarea,
  textarea:hover {
    background: none;
  }
</style>